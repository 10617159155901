import { api } from '@soc/kit/api';
import { SOC_URL } from '@soc/kit/api/constants';

import {
  tenantsFetchAbortControllerKey,
  tenantsFetchLimit,
} from '../constants';
import { TenantItem, TenantsFetch } from '../types';

export const fetchTenants: TenantsFetch = ({ search }) => {
  const trimmedSearch = search.trim();
  const apiSearch = trimmedSearch ? `&search=${trimmedSearch}` : '';

  return api<{results: TenantItem[]}>(
    `${SOC_URL}systems/?no_page=true&short=true&page_size=${tenantsFetchLimit}${apiSearch}`,
    {
      method: 'get',
      abortControllerKey: tenantsFetchAbortControllerKey,
      isMainInstallation: true,
    },
  );
};