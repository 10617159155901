import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError } from '@soc/kit/api';
import { useToast } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { fetchCreateIncidentAttach } from '../actions/fetchCreateIncidentAttach';
import { incidentCreateFetchAbortControllerKey } from '../constants';
import locales from '../locales';
import { SetNewIncidentAttachProps, UseCreateIncidentAttach } from '../types';

export const useCreateIncidentAttach: UseCreateIncidentAttach = ({
  onClose,
}) => {
  const { toastError, toastSuccess } = useToast();
  const t = useTranslate(locales);
  const [isFetching, setIsFetching] = useState(false);
  const [newIncidentAttach, setNewIncidentAttach] =
    useState<SetNewIncidentAttachProps>({});

  const createIncidentAttach = useCallback(() => {
    setIsFetching(true);

    fetchCreateIncidentAttach(newIncidentAttach)
      .then(() => {
        toastSuccess({ title: t('createSuccessAttach') });
        setIsFetching(false);
      })
      .catch((error: Error) => {
        if (error.data && error.data.upload) {
          Object.keys(error.data.upload).forEach((key) => {
            toastError({ title: error.data.upload[key][0] });
          });
        } else {
          toastError({ title: t('createError') });
        }

        if (isAbortError(error)) {
          return;
        }
        setIsFetching(false);
      })
      .finally(() => {
        onClose();
      });
  }, [newIncidentAttach, onClose]);

  useEffect(() => {
    if (newIncidentAttach.incidentKey) {
      createIncidentAttach();
    }

    return () => {
      abortApiFetch(incidentCreateFetchAbortControllerKey);
    };
  }, [newIncidentAttach]);

  return {
    isFetching,
    setNewIncidentAttach,
  };
};