import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError, useApiToastError } from '@soc/kit/api';
import { BaseSelectOption } from 'combinezone/core/Select/types';

import { fetchTenants } from '../actions/fetchTenants';
import { tenantsFetchAbortControllerKey } from '../constants';
import { convertTenantsToOptions } from '../helpers';
import { TenantItem, UseTenants } from '../types';

export const useTenants: UseTenants = () => {
  const apiToastError = useApiToastError();
  const [data, setData] = useState<BaseSelectOption[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inlineSearch, setInlineSearch] = useState('');

  const getTenants = useCallback(() => {
    setIsFetching(true);

    fetchTenants({ search: inlineSearch })
      .then((res) => {
        const convertedResultsData = convertTenantsToOptions(res.results);

        setData(convertedResultsData);
        setIsFetching(false);
      })
      .catch((error: Error) => {
        apiToastError(error);
        setData([]);

        if (isAbortError(error)) {
          return;
        }
        setIsFetching(false);
      });
  }, [inlineSearch]);

  useEffect(() => {
    if (isOpen) {
      getTenants();
    }

    return () => {
      abortApiFetch(tenantsFetchAbortControllerKey);
      setData([]);
    };
  }, [isOpen, inlineSearch]);

  return {
    data,
    isFetching,
    setIsOpen,
    setInlineSearch,
  };
};