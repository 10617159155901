import { HAS_REQUEST_CATEGORIES } from 'services/api';
import { arrayToOptions } from 'utils/helpers';

export const categoriesFetchAbortControllerKey =
  'inc/categories/fetch';

export const incidentCreateFetchAbortControllerKey = 'incident/create/fetch';

export const backendNamesToFormNamesMapping = {
  system: 'tenant',
  summary: 'subject',
  issue_type: 'issueType',
  priority: 'priority',
  detection_rules: 'detectionRules',
  mitre_cov: 'mitreCovering',
  description: 'description',
  ignore_stop_words_validation: 'isIgnoreStopWords',
  recommendations: 'recommendation',
  primary_category: 'mainCategory',
  secondary_category: 'additionalCategories',
  activity_detected: 'sources',
};

const requestOptions = HAS_REQUEST_CATEGORIES
  ? [
      'Rule Development',
      'Portal Problems',
      'Event Source Question',
      'Use Case Development',
      'Question',
      'Other',
    ]
  : [
      'Вопрос по агентам BI.ZONE EDR',
      'Вопрос по источникам событий',
      'Вопрос по работоспособности инсталляции',
      'Добавление исключений в рамках выявленных инцидентов ИБ',
      'Добавление контактов в группу рассылок',
      'Другое',
      'Запрос дополнительной информации по инциденту / правилу',
      'Корректировка существующего правила корреляции выявления инцидентов ИБ',
      'Подключение новых источников событий к мониторингу',
      'Предложение по изменениям/добавлению функционала',
      'Предоставление / блокировка доступа к SOC Portal',
      'Предоставление / блокировка доступа к серверу управления агентами BI.ZONE EDR',
      'Предоставление выгрузки по запросу клиента',
      'Создание нового правила корреляции по запросу заказчика',
      'Удаление агентов с сервера управления BI.ZONE EDR',
    ];

export const categoryOptionsTTC = {
  incident: arrayToOptions([
    'DoS/DDoS',
    'Unauthorized Access/Compromised',
    'Scans/Probes/Attempted Access',
    'Brute Force',
    'Vulnerability',
    'Defacement/Data Manipulation',
    'Data Leak',
    'Malware',
    'Malware C&C Communication',
    'Malicious Host',
    'Phishing',
    'Spam',
    'Fraud',
    'Prohibited Content',
    'Social Engineering',
    'Improper Usage',
    'Traffic Hijacking',
    'Misconfiguration',
    'Suspicious Process Activity',
  ]),
  request: arrayToOptions(requestOptions),
};