import { setLocale as setLanguage } from 'react-redux-i18n';

import moment from 'moment';

import 'moment/locale/ru';

import {
  ACTIVITY_DETECTED,
  AFFECTED_SYSTEMS,
  CATEGORY_FIELD,
  CREATE_FIELD,
  GOSSOPKA_ID,
  INSTRUCTIONS_CREATED,
  INSTRUCTIONS_NAME,
  INSTRUCTIONS_UPDATED,
  LOGSOURCES_ARCHIVED_DATE,
  LOGSOURCES_AVG_LAST_HOUR,
  LOGSOURCES_COLLECTOR,
  LOGSOURCES_CREATED,
  LOGSOURCES_EPS,
  LOGSOURCES_EPS_METRICS,
  LOGSOURCES_LAST_EVENT,
  LOGSOURCES_LAST_EVENT_TIME,
  LOGSOURCES_TAGS,
  LOGSOURCES_TYPE,
  MITRE_TACTICS,
  MITRE_TECHNIQUES,
  PARSED_ACTIVITY_DETECTED,
  PRIORITY_FIELD,
  RESOLUTION_DATE_FIELD,
  RESOLUTION_NAME_FIELD,
  RULES,
  RULES_CATEGORY,
  RULES_CONFIDENCE,
  RULES_CREATED,
  RULES_ID,
  RULES_PLATFORM,
  RULES_SEVERITY,
  RULES_TACTIC,
  RULES_TECHNIQUE,
  SERVICE_FIELD,
  SLA_BREACHED_FIELD,
  SLA_FIELD,
  STATUS_FIELD,
  SYSTEM_FIELD,
  TYPE_FIELD,
  UPDATE_FIELD,
  CUSTOMER_ASSIGNEE_FIELD,
} from '../services/api';

import { NCCCIB, socProperties } from './brending';

export function setLocale(lng) {
  moment.locale(lng);
  return setLanguage(lng);
}

export const translationObject = {
  ru: {
    titles: {
      dashboard: 'Дашборд',
      incidentList: 'Инциденты',
      softwareList: 'Установленное ПО',
      cmdbAssets: 'Список активов',
      cmdbAsset: 'Актив',
      instructions: 'Инструкции',
      instruction: 'Инструкция',
      incident: '%{incNumber}',
      articles: 'Документация',
      ruleList: 'Правила',
      protectedSystemCard: '%{systemId}',
      protectedSystems: 'Системы',
      createReport: 'Создать отчёт',
      reports: 'Отчёты',
      changePassword: 'Изменить пароль',
      files: 'Файл',
      logSources: 'Источники событий',
      rule: 'Правило',
      issues: {
        tasks: 'Задачи',
      },
    },
    general: {
      date: {
        today: 'Сегодня',
        yesterday: 'Вчера',
      },
    },
    chat: {
      noData: 'Нет сообщений',
      sendButton: 'Отправить',
    },
    main: {
      dashboard: 'Дашборд',
      incidents: 'Инциденты',
      docs: 'Документация',
      assets: 'Активы',
      issues: 'Задачи',
      protectedSystems: 'Тенанты',
      createInc: 'Зарегистрировать обращение',
      incident: 'Инцидент',
      ruleList: 'Правила',
      logSources: 'Источники',
      changeRequest: 'Запрос на изменение',
      reports: 'Отчеты',
      logout: 'Выйти',
      changePassword: 'Изменить пароль',
      rightsReserved: 'Все права защищены.',
      switchLanguage: 'Изменить язык на английский',
    },
    dashboard: {
      factoids: {
        days: 'д.',
        totalCount: 'Всего инцидентов и обращений',
        breached: 'Из них укладываются в SLA',
        criticalCount: 'критичных',
        waitingForCustomer: 'ожидают клиента',
        eps: 'Средний EPS',
        contractedEps: 'контрактный',
        maxEps: 'максимальный',
      },
      last7days: 'Последние 7 дней',
      last30days: 'Последние 30 дней',
      thisMonth: 'Этот месяц',
      lastMonth: 'Последний месяц',
      save: 'Сохранить',
      cancel: 'Отменить',
      edit: 'Редактировать',
    },
    panel: {
      noData: 'Нет данных',
    },
    incCard: {
      tags: 'Теги',
      assigned: 'Ответственный',
      assignedTime: 'Время назначения',
      detailsModal: 'Подробнее',
      close: 'Закрыть',
      infoSystem: 'Тенант',
      number: 'Номер',
      relatedIncs: 'Связанные инциденты',
      type: 'Тип',
      priority: 'Приоритет',
      initiator: 'Инициатор',
      service: 'Услуга',
      category: 'Категории',
      basicCategory: 'Категории',
      secondaryCategory: 'Дополнительные категории',
      status: 'Статус',
      created: 'Время регистрации',
      createdDate: 'Дата регистрации',
      sla: 'SLA',
      slaVerbose: 'Дата планируемого решения (SLA)',
      noSLA: 'SLA не установлен',
      slaTooltip:
        'Время, до которого SOC должен отправить первое уведомление об инциденте',
      lastUpdate: 'Последнее обновление',
      lastUpdateDate: 'Дата последнего обновления',
      lastUpdateTooltip: 'Обновлено',
      editTooltip: 'Отредактировано',
      description: 'Описание',
      more: 'Развернуть',
      less: 'Свернуть',
      error: 'Ошибка при загрузке инцидента',
      mainBlockTitle: 'Информация об инциденте',
      mainInfo: 'Основная информация',
      moreInfo: 'Дополнительная информация',
      details: 'Детали',
      actions: 'Действия',
      summary: 'Сводная информация',
      loading: 'Загрузка',
      comments: 'Комментарии к инциденту',
      faq: 'Описание и рекомендации',
      faqSolution: 'Описание, решение и рекомендации',
      addComment: 'Добавить комментарий',
      nccci: NCCCIB ? 'НКЦКИБ' : 'НКЦКИ',
      noComments: 'Комментарии отсутствуют',
      updatePriority: 'Изменение приоритета',
      resolutionName: 'Решение',
      search: 'Поиск',
      alerts: {
        searchPlaceholder: 'Свойства алерта',
      },
      header: {
        incidents: 'Инциденты',
        identifiedIocs: 'Выявленные индикаторы компрометации',
        alerts: 'Алерты',
        cmdbAssets: 'Активы',
        details: 'Детали инцидента',
        misconfigs: 'Рекомендации безопасности',
        relations: 'Связи',
      },
      statusActions: {
        commentLabel: 'Комментарий',
        commentPlaceholder: 'Оставьте комментарий',
        header: {
          reopen: 'Открыть инцидент',
          confirm: 'Подтвердить инцидент',
          close: 'Закрыть инцидент',
        },
        ok: {
          reopen: 'Открыть инцидент',
          confirm: 'Подтвердить',
          close: 'Закрыть инцидент',
        },
        cancel: {
          reopen: 'Отменить',
          confirm: 'Отменить',
          close: 'Отменить',
        },
        confirm: 'Подтвердить',
        close: 'Закрыть',
        reopen: 'Переоткрыть',
        resolutionField: 'Решение ( True Positive / False Positive )',
        message: {
          confirm: `Нажав на кнопку "Подтвердить" Вы подтвердите что инцидент является True Positive инцидентом, после чего аналитики ${socProperties.brandName} SOC начнут работу над инцидентом.`,
          close: `Нажав на кнопку "Закрыть инцидент" Вы подтвердите, что работы над инцидентом можно завершить. От Вас требуется оставить комментарий и указать, является ли инцидент True Positive ( TP ) или False Positive ( FP ).`,
        },
      },
      addCommentComponent: {
        title: 'Добавление комментария',
        add: 'Добавить',
        cancel: 'Отменить',
        textLabel: 'Текст комментария',
        successMessage: 'Комментарий добавлен!',
        solveInc: 'Решить инцидент',
        isRequired: 'Обязательно для заполнения',
        fileSizeLimit: 'Ограничение на размер файла',
      },
      recommendations: 'Рекомендации',
      resolutionDate: 'Время уведомления',
      mitre: {
        title: 'MITRE ATT&CK',
        tactics: 'Тактики',
        techniques: 'Техники',
        noData: 'Этот инцидент не был отображен в матрицу MITRE ATT&CK',
        tooltip: {
          open: 'Раскрыть',
          close: 'Закрыть',
          goToMitre: 'Перейти на сайт MITRE',
        },
      },
      rules: 'Правила',
      iocs: {
        title: 'Выявленные индикаторы компрометации',
        ioc: 'Значение',
        type: 'Тип',
        category: 'Категория',
      },
      rating: {
        title: 'Оценка работы с инцидентом',
        stars: 'Оценка',
        comment: 'Комментарий',
        feedbackButton: 'Оценить работу с инцидентом',
        submit: 'Добавить оценку',
        cancel: 'Отмена',
        help: `Нажав на звездочку, Вы сможете поставить оценку работе ${socProperties.brandName} SOC по данному инциденту`,
        error: 'При добавлении оценки произошла ошибка',
      },
      attachments: 'Вложения',
      shortDescription: 'Краткое описание',
      affectedSystems: 'Затронутые системы',
      activityDetected: 'Источник',
      NCCCI: {
        timer: `Инцидент будет отправлен в ${
          NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'
        } через:`,
        stamp: `Отправлено в ${NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'}`,
        tooltip: `Отправлено в ${NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'}`,
        sendButtonText: `Отправить в ${NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'}`,
        sendButtonProcessText: `Идет отправка инцидента в ${
          NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'
        }`,
        sendSuccess: `Инцидент успешно отправлен в ${
          NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'
        }`,
        sendError: `При отправке в ${
          NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'
        } произошла ошибка`,
        confirm: {
          title: 'Подтвердите действие',
          ok: 'Отправить',
          cancel: 'Отмена',
          body: `Отправить инцидент в ${NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'}?`,
        },
      },
      RuleList: {
        ruleField: 'rule_caption_rus',
        rule: 'Правило',
        severity: 'Приоритет',
        category: 'Категории',
        more: 'Подробнее о правиле',
      },
      noData: 'Нет данных',
    },
    alertTable: {
      header: {
        status: 'Статус',
        priority: 'Приоритет',
        title: 'Название',
        state: 'Состояние',
        group: 'Группа',
        affected_assets: 'Затронутые ресурсы',
        incident: 'Инцидент',
        updated: 'Обновлен',
        created: 'Создан',
        platform: 'Платформа',
        customerSystem: 'Система заказчика',
        actions: 'Действия',
        grouped: 'Сгруппирован',
        assignee: 'Назначен',
      },
      panel: {
        name: 'Название',
        severity: 'Уровень важности',
        confidence: 'Уровень доверия',
        classification: 'Классификация',
        created: 'Создан',
        updated: 'Обновлён',
        detection_time: 'Время регистрации',
        start_time: 'Время начала активности',
        detection_rule: 'Сработавшее правило',
        uniq_agg_rows_count: 'Накопленные данные',
        event: {
          err: 'Невозможно отобразить сгруппированное событие',
          grouped: 'Сгруппированный список',
          notGrouped: 'Плоский список',
          noData: 'Нет данных',
        },
      },
      tabs: {
        main: 'Общее',
        event: 'Событие',
        tree: 'Дерево',
        aggregatedData: 'Накопленные данные',
      },
      sections: {
        mainInfo: 'Основная информация',
        date: 'Дата',
        detectionRule: 'Сработавшее правило',
      },
    },
    incidentList: {
      loadCsv: 'Выгрузить CSV',
      csvSentByEmail: 'Выгрузка придет Вам на почту в течение 5 минут',
      sort: {
        prefix: 'Сортировать по',
        [CREATE_FIELD]: {
          helpText: 'Дата создания',
          ASC: 'Самый новый снизу',
          DESC: 'Самый новый сверху',
        },
        [UPDATE_FIELD]: {
          helpText: 'Дата обновления',
          ASC: 'Последний обновленный снизу',
          DESC: 'Последний обновленный сверху',
        },
        [STATUS_FIELD]: {
          helpText: 'Статус',
          ASC: 'Закрыто - сверху',
          DESC: 'Открыто - сверху',
        },
        [PRIORITY_FIELD]: {
          helpText: 'Приоритет',
          ASC: 'В порядке убывания критичности',
          DESC: 'В порядке возрастания критичности',
        },
      },
      search: {
        placeholder: 'Название, Описание, ID',
        button: 'Найти',
      },
      noData: 'Нет инцидентов',
      panel: {
        downloadIncident: 'Скачать PDF',
      },
      filterRule: 'Перейти к инцидентам с правилом',
    },
    logSources: {
      noData: 'Нет источников событий',
      searchPlaceholder:
        'Название, Тип источника, Сборщик событий (имя или IP)',
      total: 'Все',
      active: 'Активные',
      archive: 'Архивные',
      sort: {
        prefix: 'Сортировать по',
        [LOGSOURCES_CREATED]: {
          helpText: 'Создано',
          ASC: 'Сначала старые',
          DESC: 'Сначала новые',
        },
        [LOGSOURCES_ARCHIVED_DATE]: {
          helpText: 'Перемещено в архив',
          ASC: 'Сначала старые',
          DESC: 'Сначала новые',
        },
        [LOGSOURCES_EPS_METRICS]: {
          [LOGSOURCES_LAST_EVENT_TIME]: {
            helpText: 'Последнее событие',
            ASC: 'Сначала старые',
            DESC: 'Сначала новые',
          },
          [LOGSOURCES_AVG_LAST_HOUR]: {
            helpText: 'Средний EPS за час',
            ASC: 'По возрастанию',
            DESC: 'По убыванию',
          },
        },
      },
      loadCsv: {
        success: 'CSV будет отправлен вам на почту',
        fail: 'Ошибка при отправке CSV',
      },
    },
    logSourcesCard: {
      epsPerHour: 'EPS за час',
      epsPerDay: 'за день',
      epsPerWeek: 'за неделю',
      noMetrics: 'Источник не передает метрики',
      value: {
        epsPerHour: 'Значение EPS за час',
        epsPerDay: 'Значение EPS за день',
        epsPerWeek: 'Значение EPS за неделю',
        avg: 'среднее',
        max: 'максимальное',
      },
      created: 'Запись создана',
      lastSynced: 'Последняя синхронизация',
      lastEventTime: 'Последнее событие',
      archived: 'Запись перемещена в архив',
      tags: 'Теги:',
      source: 'Тип источника:',
      eventCollector: 'Сборщик событий:',
      incList: 'Инциденты по источнику',
      reason: 'Причина архивации',
      reasonValue: {
        INFO: 'Не найдена информация об активности источника',
        EVENTS: 'Источник не пересылает события заданный период времени',
        MANUAL: 'Источник был перенесен в архив вручную',
      },
    },
    instructions: {
      noData: 'Нет инструкций',
      noDataForSelectedLanguage:
        'Вы можете видеть только те документы, для которых существует версия на выбранном языке',
      sort: {
        prefix: 'Сортировать по',
        [INSTRUCTIONS_CREATED]: {
          helpText: 'Дата создания',
          ASC: 'Самая новая снизу',
          DESC: 'Самая новая сверху',
        },
        [INSTRUCTIONS_UPDATED]: {
          helpText: 'Дата обновления',
          ASC: 'Последняя обновленная снизу',
          DESC: 'Последняя обновленная сверху',
        },
        [INSTRUCTIONS_NAME]: {
          helpText: 'Название',
          ASC: 'Название по возрастанию',
          DESC: 'Название по убыванию',
        },
      },
      searchPlaceholder: 'Название, Описание, Тег',
      panel: {
        attachments: 'Вложения',
        mainInfo: 'Сводная информация',
      },
    },
    instructionsCard: {
      noTitle: 'Нет названия',
      noDescription: 'Нет описания',
      mainInfo: 'Общая информация',
      sensors: 'Тип источника:',
      tags: 'Теги:',
      detailsModal: 'Подробнее',
      close: 'Закрыть',
      lastUpdate: 'Обновлена',
      lastUpdateTooltip: 'Обновлено',
      editTooltip: 'Отредактировано',
      created: 'Создана',
      name: 'Название',
      more: 'Читать полностью',
      less: 'Свернуть',
      error: 'Ошибка при загрузке инструкции',
      mainBlockTitle: 'Информация',
      instruction: 'Инструкция',
      loading: 'Загрузка',
      attachments: 'Вложения',
      versions: {
        title: 'История версий',
        noData: 'Нет версий',
        comment: 'Комментарий:',
        version: 'Версия %{version}',
        cancel: 'Назад',
      },
    },
    responseInstructions: {
      noData: 'Нет инструкций',
      sort: {
        prefix: 'Сортировать по',
        [INSTRUCTIONS_CREATED]: {
          helpText: 'Дата создания',
          ASC: 'Самая новая снизу',
          DESC: 'Самая новая сверху',
        },
        [INSTRUCTIONS_UPDATED]: {
          helpText: 'Дата обновления',
          ASC: 'Последняя обновленная снизу',
          DESC: 'Последняя обновленная сверху',
        },
        [INSTRUCTIONS_NAME]: {
          helpText: 'Название',
          ASC: 'Название по возрастанию',
          DESC: 'Название по убыванию',
        },
      },
      searchPlaceholder: 'Название, Описание, Тег',
      panel: {
        attachments: 'Вложения',
        mainInfo: 'Сводная информация',
      },
    },
    responseInstructionsCard: {
      noTitle: 'Нет названия',
      noDescription: 'Нет описания',
      mainInfo: 'Общая информация',
      sensors: 'Тип источника:',
      tags: 'Теги:',
      detailsModal: 'Подробнее',
      close: 'Закрыть',
      lastUpdate: 'Обновлена',
      lastUpdateTooltip: 'Обновлено',
      editTooltip: 'Отредактировано',
      created: 'Создана',
      name: 'Название',
      more: 'Читать полностью',
      less: 'Свернуть',
      error: 'Ошибка при загрузке инструкции',
      mainBlockTitle: 'Информация',
      instruction: 'Инструкция',
      loading: 'Загрузка',
      attachments: 'Вложения',
      versions: {
        title: 'История версий',
        noData: 'Нет версий',
        comment: 'Комментарий:',
        version: 'Версия %{version}',
        cancel: 'Назад',
      },
    },
    articles: {
      noData: 'Нет статей',
      sort: {
        prefix: 'Сортировать по',
        [INSTRUCTIONS_CREATED]: {
          helpText: 'Дата создания',
          ASC: 'Самая новая снизу',
          DESC: 'Самая новая сверху',
        },
        [INSTRUCTIONS_UPDATED]: {
          helpText: 'Дата обновления',
          ASC: 'Последняя обновленная снизу',
          DESC: 'Последняя обновленная сверху',
        },
        [INSTRUCTIONS_NAME]: {
          helpText: 'Название',
          ASC: 'Название по возрастанию',
          DESC: 'Название по убыванию',
        },
      },
      searchPlaceholder: 'Название, Описание, Тег',
      panel: {
        attachments: 'Вложения',
        mainInfo: 'Сводная информация',
      },
    },
    articlesCard: {
      noTitle: 'Нет названия',
      noDescription: 'Нет описания',
      mainInfo: 'Общая информация',
      sensors: 'Тип источника:',
      tags: 'Теги:',
      detailsModal: 'Подробнее',
      close: 'Закрыть',
      lastUpdate: 'Обновлена',
      lastUpdateTooltip: 'Обновлено',
      editTooltip: 'Отредактировано',
      created: 'Создана',
      name: 'Название',
      more: 'Читать полностью',
      less: 'Свернуть',
      error: 'Ошибка при загрузке инструкции',
      mainBlockTitle: 'Информация',
      instruction: 'Статья',
      loading: 'Загрузка',
      attachments: 'Вложения',
      versions: {
        title: 'История версий',
        noData: 'Нет версий',
        comment: 'Комментарий:',
        version: 'Версия %{version}',
        cancel: 'Назад',
      },
    },
    documents: {
      instructions: 'Инструкции\xa0по\xa0настройке источников событий',
      responseInstructions: 'Инструкции\xa0по\xa0реагированию на инциденты',
      articles: 'Статьи',
      noArticles: 'Нет статей',
      rules: 'Правила',
    },
    documentationCardBase: {
      inAnotherLanguage: 'На другом языке',
      ruVersion: 'Русская версия',
      enVersion: 'Английская версия',
      downloadPDF: 'Скачать PDF',
    },
    systemsFilter: {
      all: 'Выбрать все',
      only: 'Только',
      cancel: 'Отмена',
      apply: 'Применить',
      placeholder: 'Отображаемые системы',
    },
    sort: {
      buttons: {
        control: {
          tooltip: 'Сортировка',
        },
        reset: {
          tooltip: 'Очистить поля',
        },
      },
      condition: {
        placeholder: 'Условие',
      },
      direction: {
        placeholder: 'Значение',
      },
    },
    download: {
      buttons: {
        csv: {
          text: 'Экспорт',
          tooltip: 'Экспорт CSV',
        },
      },
      notifications: {
        success: 'Выгрузка придет Вам на почту в течение 5 минут',
      },
    },
    filterPanel: {
      searchButton: 'Найти',
      sortControl: 'Выбор сортировки',
      filterButton: 'Фильтры',
      removeFilter: 'Убрать фильтр',
      rangePicker: {
        format: 'D MMMM YYYY',
        chooseStart: 'Начальная дата',
        chooseEnd: 'Конечная дата',
        last7days: 'Последние 7 дней',
        last30days: 'Последние 30 дней',
        thisMonth: 'Этот месяц',
        lastMonth: 'Последний месяц',
      },
      addFilter: 'Добавить фильтр',
      filter: {
        chooseAll: 'Выбрать все',
        notSelected: 'Не выбрано',
        clearAll: 'Сбросить',
        notFound: 'Ничего не найдено',
        searchPlaceholder: 'Поиск',
        apply: 'Применить',
        cancel: 'Отмена',
        absent: 'Отсутствует',
        any: 'Любой',
        specific: 'Конкретный',
      },
      incidents: {
        types: {
          [STATUS_FIELD]: 'Статус',
          [PRIORITY_FIELD]: 'Приоритет',
          [CATEGORY_FIELD]: 'Категория',
          [CREATE_FIELD]: 'Время регистрации',
          [SYSTEM_FIELD]: 'Тенант',
          [ACTIVITY_DETECTED]: 'Источник',
          [SLA_BREACHED_FIELD]: 'SLA нарушен',
          [RESOLUTION_DATE_FIELD]: 'Время уведомления',
          [MITRE_TACTICS]: 'Тактика',
          [MITRE_TECHNIQUES]: 'Техника',
          [SLA_FIELD]: 'SLA',
          [`${AFFECTED_SYSTEMS}_ip`]: 'Хост',
          [`${AFFECTED_SYSTEMS}_actor`]: 'УЗ',
          [GOSSOPKA_ID]: 'НКЦКИ',
          [RULES]: 'Правило',
          [SERVICE_FIELD]: 'Услуга',
          [RESOLUTION_NAME_FIELD]: 'Решение',
          [TYPE_FIELD]: 'Тип инцидента',
          [PARSED_ACTIVITY_DETECTED]: 'Источник событий',
          [CUSTOMER_ASSIGNEE_FIELD]: 'Ответственный',
        },
      },
      sources: {
        types: {
          [SYSTEM_FIELD]: 'Тенант',
          [LOGSOURCES_TAGS]: 'Теги',
          [LOGSOURCES_TYPE]: 'Тип источника',
          [LOGSOURCES_COLLECTOR]: 'Сборщик событий',
          [LOGSOURCES_EPS]: 'Средний EPS',
          [LOGSOURCES_LAST_EVENT]: 'Последние события',
          [LOGSOURCES_CREATED]: 'Первые события',
        },
      },
      instructions: {
        types: {
          [INSTRUCTIONS_CREATED]: 'Время создания',
          [INSTRUCTIONS_UPDATED]: 'Время обновления',
        },
      },
      ruleList: {
        types: {
          [RULES_SEVERITY]: 'Уровень важности',
          [RULES_CATEGORY]: 'Категории',
          [RULES_CONFIDENCE]: 'Уровень доверия',
          [RULES_CREATED]: 'Дата создания правила',
          [RULES_TACTIC]: 'MITRE ATT&CK тактики',
          [RULES_TECHNIQUE]: 'MITRE ATT&CK техники',
          [RULES_ID]: 'Правила',
          availableForService: 'Доступно на уровнях',
          isRequested: 'Клиентское правило',
          mode: 'Режим активации',
          [RULES_PLATFORM]: 'MITRE ATT&CK платформа',
        },
      },
      boolean: {
        true: 'Да',
        false: 'Нет',
      },
    },
    dashboardWidgetsMenu: {
      widgets: 'Виджеты',
    },
    widget: {
      noData: 'Нет данных за выбранный период',
    },
    incTable: {
      created: 'Время регистрации',
      updated: 'Последнее обновление',
      id: 'Номер инцидента',
      socProperties: 'Тема',
      initiator: 'Инициатор',
      infoSystem: 'Тенант',
      service: 'Услуга',
      category: 'Категории',
      priority: 'Приоритет',
      status: 'Статус',
      cardTitle: 'Последние инциденты',
      search: 'Поиск',
      pagination: '%{low}-%{high} из %{total} записей',
    },
    protectedSystems: {
      title: 'Тенанты',
      fio: 'ФИО',
      email: 'Электронная почта',
      workPhone: 'Рабочий',
      mobilePhone: 'Мобильный',
      slaCategory: 'Уровень',
      slaHours: 'Значение',
      slaValue: {
        isCalendar: '%{hours} р. ч.',
        notCalendar: '%{hours} ч.',
      },
      mainInfo: 'Основная информация',
      fullName: 'Название',
      id: 'Идентификатор',
      status: 'Статус',
      services: 'Услуги',
      contract: 'Договор',
      contractField: '%{name} №%{number} от %{start}',
      startDate: 'Дата подключения',
      endDate: 'Дата истечения договора',
      sla: 'SLA',
      contacts: 'Контакты',
      serviceManager: 'Сервис-менеджер',
      serviceManagerDouble: 'Дублирующий сервис-менеджер',
      dedicatedAnalyst: 'Выделенный аналитик',
      dedicatedAnalystDouble: 'Дублирующий аналитик',
      customerContacts: 'Контакты со стороны клиента',
    },
    createInc: {
      priorityTypes: {
        low: 'Низкий',
        medium: 'Средний',
        high: 'Высокий',
      },
      categoryTypes: {
        DDoS: 'Отказ в обслуживании (Dos/DDos)',
        unauthorizedAccessCompromised: ' Несанкционированный доступ',
        scansAttemptedAccess: 'Сбор информации/Попытки НСД',
        bruteForce: 'Brute Force',
        vulnerability: 'Уязвимость',
        defacementDataManipulation: 'Defacement/Data manipulation',
        dataLeak: 'Утечка данных',
        malware: 'Вредоносное ПО',
        malwareCCCoumunication: 'Malware C&C Communication',
        maliciousHost: 'Вредоносный хост',
        phishing: 'Фишинг',
        spam: 'Спам',
        fraud: 'Мошенничество',
        prohibitedContent: 'Запрещенный контент',
        socialEngineering: 'Социальная инженерия',
        improperUsage: 'Нарушение политик ИБ',
        trafficHijacking: 'Traffic hijacking',
        misconfiguration: 'Некорректная конфигурация ИБ',
        suspiciousProcessActivity: 'Подозрительная активность процесса',
      },
      issueType: {
        label: 'Тип инцидента',
      },
      title: 'Регистрация инцидента',
      cancel: 'Отмена',
      submit: 'Отправить',
      summary: 'Тема',
      summaryRequired: 'Введите тему инцидента',
      description: 'Описание инцидента',
      descriptionRequired: 'Введите описание инцидента',
      systemService: 'Информационная система',
      systemServiceRequired: 'Данное поле обязательно',
      NCCCIWarning: `Инцидент с данной системой будет автоматически отправлен в ${
        NCCCIB ? 'НКЦКИБ' : 'НКЦКИ'
      } %{duration} после отправки в SOC`,
      priority: 'Приоритет',
      category: {
        label: 'Категория',
        placeholder: 'Выберите категории',
        isRequired: 'Категория необходима',
      },
      attachments: {
        label: 'Вложение',
        button: 'Выберите файл',
        hint: 'Размер файла не должен превышать 50Мб',
      },
      errors: {
        system: 'Информационная система: %{error}',
        service: 'Услуга: %{error}',
      },
      attachmentsError: 'Ошибка при добавлении вложений',
      successMessage:
        'Ваш инцидент был успешно зарегистрирован под номером %{incKey}',
    },
    reports: {
      filename: 'Название',
      size: 'Размер',
      title: 'Отчеты',
      create: 'Создать отчет',
      createDate: 'Дата создания',
      pagination: '%{low}-%{high} из %{total} записей',
      time: 'Временной период',
      system: 'Тенант',
      actions: {
        download: 'Скачать отчет',
        edit: 'Создать новый отчет с этими же параметрами',
        delete: 'Удалить отчет',
        confirmDelete: 'Подтвердите действие',
      },
      reportShedules: 'Расписания',
      groupActions: {
        download: 'Загрузить',
        delete: 'Удалить',
        confirmDelete0: 'Удалить %{count} отчет',
        confirmDelete1: 'Удалить %{count} отчета',
        confirmDelete2: 'Удалить %{count} отчетов',
      },
      deleteError: 'Ошибка при удалении отчета',
    },
    sheduleCard: {
      editTooltip: 'Дата создания',
      periodicity: 'Периодичность:',
      startTime: 'Начало действия:',
      system: 'Тенант:',
      recipients: 'Получатели:',
      editReport: 'Редактировать отчет',
    },
    downloadReport: {
      download: 'Скачать',
    },
    createReport: {
      newReport: 'Новый отчет',
      reportname: 'Название',
      title: 'Создать отчет',
      save: 'сохранить',
      system: 'Тенанты',
      type: 'Период отчета',
      placeholders: {
        title: 'Введите название',
        system: 'Выберите тенанты',
        periodicity: 'Выберите из списка',
        email: 'example1@mail.com, example2@mail.com',
      },
      requiredTitles: {
        emails: 'Введите корректные email адреса',
        reportName: 'Название отчета обязательно',
      },
      email: 'Email адреса получателей',
      info: {
        reports: 'Отчеты будут формироваться с ',
        oneReport: 'Отчет сформируется один раз, за период с ',
        to: ' по ',
        clarification:
          'Сбор данных для каждого отчета будет производится с момента формирования предыдущего отчета до момента формирования текущего',
        everyDay: 'ежедневно',
        everyWeek: 'еженедельно',
        twiceAMonth: 'раз в две недели',
        onceAMonth: 'ежемесячно',
      },
      periodicity: {
        label: 'Периодичность',
        everyDay: 'Каждый день',
        everyWeek: 'Раз в неделю',
        twiceAMonth: 'Раз в две недели',
        onceAMonth: 'Раз в месяц',
        forPeriod: 'За период',
        startLabel: 'Дата и время начала',
        none: 'Нет',
      },
      periodPatternDidNotMatch: 'Некорректная дата',
      systemsIsRequired: 'Выберите системы',
      successMessage: 'Файл будет готов в течение 5 минут',
      systemsList: 'Список систем',
      setupReport: 'Настройка отчета',
      noSystems: 'Список систем пуст',
      actions: {
        delete: 'Убрать из списка',
        confirmDelete: 'Подтвердите действие',
      },
      groupActions: {
        delete: 'Убрать из списка',
        confirmDelete0: 'Убрать из списка %{count} систему',
        confirmDelete1: 'Убрать из списка %{count} системы',
        confirmDelete2: 'Убрать из списка %{count} систем',
      },
      createConfirm: {
        report: 'Отчет ',
        willBeGenerated: ' будет сформирован в ближайшее время',
        settings: 'Настройки для ',
        saved: ' сохранены',
        deleted: ' удален',
      },
      createButton: 'Создать отчет',
      cancelButton: 'отмена',
      addButton: 'Добавить системы',
    },
    login: {
      username: 'Логин',
      usernameRequired: 'Введите логин',
      password: 'Пароль',
      passwordRequired: 'Введите пароль',
      submit: 'Войти',
      pinSent: 'Вам отправлен PIN код по смс, введите PIN код',
      cancel: 'Отмена',
      pinResend: 'Повторно запросить PIN код',
      pinResendAccess: 'Повторно запросить PIN-код можно через ',
    },
    editWidgetMenu: {
      modalTitle: 'Изменить виджет',
      title: 'Заголовок',
      titleRequired: 'Введите название графика',
      type: 'Тип',
    },
    showRefreshPageNotification: {
      reload: 'Перезагрузить',
      message: 'Доступна новая версия!',
      description:
        'Доступна новая версия приложения. Пожалуйста, обновите страницу.',
    },
    showNewVersionNotification: {
      message: 'Новая версия',
      description: 'Смотреть список изменений',
    },
    mitreWidget: {
      title: 'MITRE ATT&CK™',
      helpFrequency: 'Частота фиксации техники в инцидентах',
      critical: 'Очень часто',
      high: 'Часто',
      medium: 'Редко',
      low: 'Очень редко',
      none: 'Не зафиксированно',
      hideMitreTechniques:
        'Скрыть техники MITRE ATT&CK по которым нет инцидентов',
      technique: {
        id: 'ID',
        link: 'Ссылка',
        description: 'Описание',
        incidents: 'Инциденты',
        goToIncs: 'Перейти к списку инцидентов',
        name: 'Название',
      },
    },
    charts: {
      countIncs: 'Количество инцидентов',
      [PRIORITY_FIELD]: 'Приоритет',
      [CATEGORY_FIELD]: 'Категория',
      [CREATE_FIELD]: 'Дата создания',
      [STATUS_FIELD]: 'Статус',
      [SLA_BREACHED_FIELD]: 'SLA',
      [`${SLA_BREACHED_FIELD}Types`]: {
        true: 'Не выполнен',
        false: 'Выполнен',
      },
    },
    duration: {
      millisecond: '%{ms} мс.',
      second: '%{s} сек. %{ms} мс.',
      minute: '%{m} мин. %{s} сек.',
      hour: '%{h} ч. %{m} мин.',
      day: '%{d} д. %{h} ч.',
    },
    widgets: {
      priorityWidget: {
        title: 'Статистика по приоритету',
        description: 'Описание',
      },
      statusWidget: {
        title: 'Статистика по статусу',
        description: 'Описание',
      },
      registrationDynamicsWidget: {
        title: 'Динамика регистрации инцидентов',
        description: 'Описание',
      },
      categoryWidget: {
        title: 'Статистика по категории',
        description: 'Описание',
      },
      slaWidget: {
        title: 'Статистика по SLA',
        description: 'Описание',
      },
      topDomainsWidget: {
        title: 'Топ 5 доменов',
        description: 'Описание',
      },
      topIpAddressesWidget: {
        title: 'Топ 5 IP-адресов',
        description: 'Описание',
      },
      epsWidget: {
        title: 'EPS',
        description: 'Количество событий в секунду',
        generalBlock: {
          title: 'Время всех превышений',
        },
        tooltip: {
          averageEps: 'Средний EPS',
          excessesPerPeriod: 'Превышений за период',
          excessInfo: 'Информация о превышении',
          excessDuration: 'Длительность',
          maxValue: 'Максимальное значение',
          duration: {
            y: 'л.',
            M: 'мес.',
            w: 'нед.',
            d: 'д.',
            m: 'мин.',
            h: 'ч.',
            s: 'сек.',
            S: 'мс.',
          },
        },
      },
      widgetTypes: {
        pie: 'Круговая диаграмма',
        bar: 'Гистограмма',
        horizontalBar: 'Линейчатая диаграмма',
        line: 'График',
        gauge: 'Датчик',
      },
    },
    addSystems: {
      title: 'Добавление системы',
      saveButton: 'Сохранить',
      cancelButton: 'Отменить',
      systems: 'Системы',
      placeholder: 'Название системы',
      addedSystems: 'Добавленные системы',
      notAddedSystems: 'Недобавленные системы',
      multipleSystemsMessage: 'Для каждой системы будет создан отдельный отчет',
    },
    downloadNotification: {
      countHeader: 'Загрузка %{count} файлов',
      filenameHeader: 'Загрузка %{filename}',
    },
    newIncidentMessage: {
      message: 'Зарегистрирован новый инцидент: %{key}',
    },
    changePassword: {
      header: 'Изменить пароль',
      oldPassword: 'Старый пароль',
      password: 'Новый пароль',
      confirm: 'Подтвердить пароль',
      mustMatch: 'Пароли должны совпадать',
      passwordInvalid: 'Пароль не может содержать только цифры',
      passwordLengthInvalid: 'Длина паролья должна быть больше девяти символов',
      success: 'Пароль был изменен',
      cancel: 'Отмена',
      submit: 'Изменить',
      required: 'Это поле обязательно',
    },
    files: {
      downloadButton: 'Скачать',
      noPreview: 'Предварительный просмотр недоступен',
      buttonGoMain: 'На главную',
      buttonGoBack: 'Назад',
      notFound: 'Файл не найден',
      error: 'При обращении к серверу произошла ошибка',
    },
    ReportsShedules: {
      noData: 'Нет данных',
    },
    ruleList: {
      rules: 'Правила',
      mitre: 'MITRE ATT&CK',
      noData: 'Нет правил',
      searchPlaceholder: 'Название',
      sortButton: 'Сортировка',
      mainInfo: 'Основная информация',
      description: 'Описание',
      isRequested: 'Контекст',
      properties: 'Свойства',
      client: 'Клиент',
      coverageWidget: {
        title: 'Покрытие матрицы MITRE ATT&CK',
        help: `Доля техник и сабтехник матрицы MITRE ATT&CK Enterprise (включая все подмножества - PRE, Windows, macOS, Linux, Cloud, Network, Containers), покрываемых набором правил с учетом выбранного фильтра. Если фильтр не установлен, то учитываются все доступные правила ${socProperties.brandName} SOC`,
      },
      miniDashboard: {
        count: 'Всего правил',
        countLastMonth: 'За последний месяц',
        countIsRequested: 'Клиентских правил',
      },
      sort: {
        [RULES_CREATED]: {
          helpText: 'Дата создания',
          ASC: 'Самое новое правило снизу',
          DESC: 'Самое новое правило сверху',
        },
      },
      mitreWidget: {
        description: 'Описание',
        name: 'Название',
        count: 'Правила',
        tactics: 'Тактики',
        hideNone: 'Скрывать техники не покрытые правилами',
        tooltip: 'Количество техник покрываемых правилами',
      },
    },
    rulePage: {
      sensors: 'Источники',
      mainInfo: 'Основная информация',
      confidence: 'Уровень доверия',
      severity: 'Уровень важности',
      category: 'Категории',
      links: 'Ссылки',
      confidenceHelp:
        'Показывает насколько правило точно, и способно давать ложные срабатывания',
      severityHelp:
        'Показывает, насколько сильное влияние на инфраструктуру, может оказать инцидент, зарегистрированный в результате срабатывания данного правила',
      description: 'Описание',
      caption: 'Заголовок',
      relatedIncidents: 'Связанные инциденты',
      captionField: 'rule_caption_rus',
      descriptionField: 'description_rus',
      noDescription: 'Нет описания',
      noLinks: 'Нет ссылок',
      mitre: 'MITRE ATT&CK',
      platforms: 'Платформы',
      createdTooltip: 'Дата создания правила',
      [RULES_CREATED]: 'Дата создания правила',
      mode: 'Режим активации',
      modeTooltip:
        'Показывает, каким образом правило активируется для вновь подключенного клиента. Auto - правило будет автоматически работать, если в вашей инфраструктуре имеются требуемые источники. Manual - по-умолчанию правило отключено, но может быть включения по вашему запросу.',
      activationMode: 'Автоматический режим активации',
      isRequested: 'Клиентское правило',
      isRequestedTooltip:
        'Означает, что правило является кастомным, т.е. разработано по вашему требованию. Сфера действия данного правила ограничена только лишь вашей инфраструктурой.',
      availableForServices: 'Доступно на уровнях',
      name: 'Название',
      priorityTypes: {
        low: 'Низкий',
        medium: 'Средний',
        high: 'Высокий',
      },
      modeTypes: {
        auto: 'Автоматический',
        manual: 'Ручной',
      },
      isRequestedTypes: {
        true: 'Да',
        false: 'Нет',
      },
    },
    theme: {
      switch: 'Переключить тему',
    },
  },
  en: {
    titles: {
      dashboard: 'Dashboard',
      cmdbAssets: 'Assets list',
      cmdbAsset: 'Asset',
      incidentList: 'Incidents',
      softwareList: 'Installed software',
      instructions: 'Instructions',
      instruction: 'Instruction',
      incident: '%{incNumber}',
      articles: 'Documentation',
      ruleList: 'Rules',
      protectedSystemCard: '%{systemId}',
      protectedSystems: 'Systems',
      createReport: 'Create report',
      reports: 'Reports',
      changePassword: 'Change password',
      files: 'File',
      logSources: 'Log sources',
      rule: 'Rule',
      issues: {
        tasks: 'Tasks',
      },
    },
    general: {
      date: {
        today: 'Today',
        yesterday: 'Yesterday',
      },
    },
    chat: {
      noData: 'No messages',
      sendButton: 'Send',
    },
    main: {
      dashboard: 'Dashboard',
      incidents: 'Incidents',
      protectedSystems: 'Tenants',
      createInc: 'Submit a claim',
      incident: 'Incident',
      ruleList: 'Rules',
      issues: 'Issues',
      logSources: 'Log sources',
      changeRequest: 'Request for change',
      reports: 'Reports',
      docs: 'Documentation',
      assets: 'Assets',
      logout: 'Log out',
      changePassword: 'Change password',
      rightsReserved: 'All rights reserved.',
      switchLanguage: 'Switch language to russian',
    },
    panel: {
      noData: 'No data',
    },
    dashboard: {
      factoids: {
        days: 'd.',
        totalCount: 'Total incidents and appeals',
        breached: 'Of them are included in the SLA',
        criticalCount: 'critical',
        waitingForCustomer: 'waiting for customer',
        eps: 'Average EPS',
        contractedEps: 'contractual',
        maxEps: 'highest value',
      },
      last7days: 'Last 7 days',
      last30days: 'Last 30 days',
      thisMonth: 'Current month',
      lastMonth: 'Previous month',
      save: 'Save',
      cancel: 'Cancel',
      edit: 'Edit',
    },
    incidentList: {
      loadCsv: 'Load CSV',
      csvSentByEmail: 'The file will come to your email in 5 minutes',
      sort: {
        prefix: 'Sort by',
        [CREATE_FIELD]: {
          helpText: 'Date of creation',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [UPDATE_FIELD]: {
          helpText: 'Date of update',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [STATUS_FIELD]: {
          helpText: 'Status',
          ASC: 'Closed on top',
          DESC: 'Opened on top',
        },
        [PRIORITY_FIELD]: {
          helpText: 'Priority',
          ASC: 'Highest to lowest',
          DESC: 'Lowest to highest',
        },
      },
      search: {
        placeholder: 'Title, Description, ID',
        button: 'Search',
      },
      noData: 'No incidents',
      panel: {
        downloadIncident: 'Download PDF',
      },
      filterRule: 'Go to incidents with the rule',
    },
    incCard: {
      tags: 'Tags',
      assigned: 'Assigned',
      assignedTime: 'Assigned time',
      detailsModal: 'Details',
      close: 'Close',
      infoSystem: 'Tenant',
      number: 'ID',
      relatedIncs: 'Related incidents',
      type: 'Type',
      priority: 'Priority',
      initiator: 'Registered by',
      service: 'Service',
      category: 'Categories',
      basicCategory: 'Categories',
      secondaryCategory: 'Secondary categories',
      status: 'Status',
      created: 'Registered at',
      createdDate: 'Date registered',
      sla: 'SLA',
      slaVerbose: 'Planned resolution date (SLA)',
      noSLA: 'SLA not set',
      slaTooltip: 'Time by which the SOC should send the first incident report',
      lastUpdate: 'Updated at',
      lastUpdateDate: 'Date of last update',
      lastUpdateTooltip: 'Updated',
      editTooltip: 'Edited',
      description: 'Description',
      more: 'More',
      less: 'Less',
      error: 'Fetching incident: error',
      mainBlockTitle: 'Incident info',
      mainInfo: 'Short description',
      moreInfo: 'Additional information',
      details: 'Details',
      actions: 'Actions',
      summary: 'Summary information',
      loading: 'Loading',
      comments: 'Comments',
      faq: 'Description and recommendations',
      faqSolution: 'Description, resolution and recommendations',
      addComment: 'Add a comment',
      nccci: NCCCIB ? 'NCCCIB' : 'NCCCI',
      noComments: 'There are no comments for this incident',
      updatePriority: 'Сhange of priority',
      resolutionName: 'Resolution',
      search: 'Search',
      alerts: {
        searchPlaceholder: 'Alert properties',
      },
      header: {
        incidents: 'Incidents',
        identifiedIocs: 'Identified IOCs',
        alerts: 'Alerts',
        cmdbAssets: 'Assets',
        details: 'Incident details',
        misconfigs: 'Security recommendations',
        relations: 'Relations',
      },
      addCommentComponent: {
        title: 'Add a comment',
        add: 'Add',
        cancel: 'Cancel',
        textLabel: 'Comment',
        successMessage: 'Comment added!',
        solveInc: 'Solve the incident',
        isRequired: 'Field is required',
        fileSizeLimit: 'File size limit',
      },
      statusActions: {
        commentLabel: 'Comment',
        commentPlaceholder: 'Leave a comment',
        header: {
          reopen: 'Reopen incident',
          confirm: 'Confirm incident',
          close: 'Close incident',
        },
        ok: {
          reopen: 'Reopen incident',
          confirm: 'Confirm',
          close: 'Close',
        },
        cancel: {
          reopen: 'Cancel',
          confirm: 'Cancel',
          close: 'Cancel',
        },
        confirm: 'Confirm',
        close: 'Close',
        reopen: 'Reopen',
        resolutionField: 'Resolution (True Positive / False Positive)',
        message: {
          confirm: `By clicking on the "Confirm" button you will confirm that the incident is a True Positive incident, after which ${socProperties.brandName} SOC analysts will start working on the incident.`,
          close: `By clicking on the "Close" button you confirm that the work on the incident can be completed. You are required to leave a comment and indicate whether the incident is True Positive (TP) or False Positive (FP) .`,
        },
      },
      recommendations: 'Recommendations',
      resolutionDate: 'Notified at',
      mitre: {
        title: 'MITRE ATT&CK',
        tactics: 'Tactics',
        techniques: 'Techniques',
        noData: 'This incident was not mapped to the MITRE ATT&CK matrix',
        tooltip: {
          open: 'Open',
          close: 'Close',
          goToMitre: 'Go to MITRE website',
        },
      },
      rules: 'Rules',
      iocs: {
        title: 'Identified indicators of compromise',
        ioc: 'Value',
        type: 'Type',
        category: 'Category',
      },
      rating: {
        title: 'Incident feedback',
        stars: 'Rating',
        comment: 'Comment',
        feedbackButton: 'Rate SOC work',
        submit: 'Submit',
        cancel: 'Cancel',
        help: `By clicking on the star, you can rate the work of ${socProperties.brandName} SOC for this incident`,
        error: 'An error occurred while adding the rating',
      },
      attachments: 'Attachments',
      shortDescription: 'Short description',
      affectedSystems: 'Affected systems',
      activityDetected: 'Source of detection',
      NCCCI: {
        timer: `Incident will be sent to ${NCCCIB ? 'NCCCIB' : 'NCCCI'} in:`,
        stamp: `Sent to ${NCCCIB ? 'NCCCIB' : 'NCCCI'} on`,
        tooltip: `Sent to ${NCCCIB ? 'NCCCIB' : 'NCCCI'}`,
        sendButtonText: `Send to ${NCCCIB ? 'NCCCIB' : 'NCCCI'}`,
        sendButtonProcessText: `The incident is being sent to the ${
          NCCCIB ? 'NCCCIB' : 'NCCCI'
        }`,
        sendSuccess: `The incident was successfully sent to ${
          NCCCIB ? 'NCCCIB' : 'NCCCI'
        }`,
        sendError: `An error occurred while sending to ${
          NCCCIB ? 'NCCCIB' : 'NCCCI'
        }`,
        confirm: {
          title: 'Confirmation',
          ok: 'Send',
          cancel: 'Cancel',
          body: `Send an incident to ${NCCCIB ? 'NCCCIB' : 'NCCCI'}?`,
        },
      },
      RuleList: {
        ruleField: 'rule_caption_eng',
        rule: 'Rule',
        severity: 'Severity',
        category: 'Categories',
        more: 'More about rule',
      },
      noData: 'No data',
    },
    logSources: {
      noData: 'No log sources',
      searchPlaceholder: 'Title, Source type, Event collector (name or IP)',
      total: 'All',
      active: 'Active',
      archive: 'Archive',
      sort: {
        prefix: 'Sort by',
        [LOGSOURCES_CREATED]: {
          helpText: 'Created',
          ASC: 'Old first',
          DESC: 'New first',
        },
        [LOGSOURCES_ARCHIVED_DATE]: {
          helpText: 'Moved to archive',
          ASC: 'Old first',
          DESC: 'New first',
        },
        [LOGSOURCES_EPS_METRICS]: {
          [LOGSOURCES_LAST_EVENT_TIME]: {
            helpText: 'Last event',
            ASC: 'Old first',
            DESC: 'New first',
          },
          [LOGSOURCES_AVG_LAST_HOUR]: {
            helpText: 'Average EPS per hour',
            ASC: 'Ascending',
            DESC: 'Descending',
          },
        },
      },
      loadCsv: {
        success: 'CSV will be sent to you by email',
        fail: 'Error sending CSV',
      },
    },
    alertTable: {
      header: {
        status: 'Status',
        priority: 'Priority',
        title: 'Title',
        state: 'State',
        group: 'Group',
        affected_assets: 'Affected assets',
        incident: 'Incident',
        updated: 'Updated',
        created: 'Created',
        platform: 'Platform',
        customerSystem: 'Customer system',
        actions: 'Actions',
        grouped: 'Grouped by',
        assignee: 'Assignee',
      },
      panel: {
        name: 'Title',
        severity: 'Severity',
        confidence: 'Confidence',
        classification: 'Classification',
        created: 'Created',
        updated: 'Updated',
        detection_time: 'Detection time',
        start_time: 'Start time',
        detection_rule: 'Detection rule',
        uniq_agg_rows_count: 'Aggregated data',
        event: {
          err: 'Unable to display grouped event',
          grouped: 'Grouped',
          notGrouped: 'Flat',
          noData: 'No Data',
        },
      },
      tabs: {
        main: 'General',
        event: 'Event',
        tree: 'Tree',
        aggregatedData: 'Aggregated data',
      },
      sections: {
        mainInfo: 'Main info',
        date: 'Date',
        detectionRule: 'Detection rule',
      },
    },
    logSourcesCard: {
      epsPerHour: 'EPS per hour',
      epsPerDay: 'per day',
      epsPerWeek: 'per week',
      noMetrics: 'Source does not send metrics',
      value: {
        epsPerHour: 'Value EPS per hour',
        epsPerDay: 'Value EPS per day',
        epsPerWeek: 'Value EPS per week',
        avg: 'average',
        max: 'maximum',
      },
      created: 'Record created',
      lastSynced: 'Last sync',
      lastEventTime: 'Last event',
      archived: 'Record moved to archive',
      firstEvents: 'First events',
      tags: 'Tags:',
      source: 'Source type:',
      eventCollector: 'Event collector:',
      incList: 'Incidents by source',
      reason: 'Reason for archiving',
      reasonValue: {
        INFO: 'Source activity information not found',
        EVENTS: 'Source does not forward events for a specified period of time',
        MANUAL: 'The source was moved to the archive manually',
      },
    },
    instructions: {
      noData: 'No instructions',
      noDataForSelectedLanguage:
      'You can only see the documents available in the selected language of interface',
      sort: {
        prefix: 'Sort by',
        [INSTRUCTIONS_CREATED]: {
          helpText: 'Date of creation',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [INSTRUCTIONS_UPDATED]: {
          helpText: 'Update date',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [INSTRUCTIONS_NAME]: {
          helpText: 'Title',
          ASC: 'Name ascending',
          DESC: 'Name descending',
        },
      },
      searchPlaceholder: 'Title, Description, Tag',
      panel: {
        attachments: 'Attachments',
        mainInfo: 'Short description',
      },
    },
    instructionsCard: {
      noTitle: 'No title',
      noDescription: 'No description',
      mainInfo: 'Short description',
      sensors: 'Logsource type :',
      tags: 'Tags:',
      detailsModal: 'Details',
      close: 'Close',
      lastUpdate: 'Last update',
      lastUpdateTooltip: 'Last update',
      editTooltip: 'Created',
      created: 'Created',
      name: 'Title',
      more: 'More',
      less: 'Less',
      error: 'Fetching instruction: error',
      mainBlockTitle: 'Instruction info',
      instruction: 'Instruction',
      loading: 'Loading',
      attachments: 'Attachments',
      versions: {
        title: 'Versions history',
        noData: 'No history',
        comment: 'Comment:',
        version: 'Version %{version}',
        cancel: 'Cancel',
      },
    },
    responseInstructions: {
      noData: 'No instructions',
      sort: {
        prefix: 'Sort by',
        [INSTRUCTIONS_CREATED]: {
          helpText: 'Date of creation',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [INSTRUCTIONS_UPDATED]: {
          helpText: 'Update date',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [INSTRUCTIONS_NAME]: {
          helpText: 'Title',
          ASC: 'Title ascending',
          DESC: 'Title descending',
        },
      },
      searchPlaceholder: 'Title, Description, Tag',
      panel: {
        attachments: 'Attachments',
        mainInfo: 'Short description',
      },
    },
    responseInstructionsCard: {
      noTitle: 'No title',
      noDescription: 'No description',
      mainInfo: 'Short description',
      sensors: 'Logsource type :',
      tags: 'Tags:',
      detailsModal: 'Details',
      close: 'Close',
      lastUpdate: 'Last update',
      lastUpdateTooltip: 'Last update',
      editTooltip: 'Created',
      created: 'Created',
      name: 'Title',
      more: 'More',
      less: 'Less',
      error: 'Fetching instruction: error',
      mainBlockTitle: 'Instruction info',
      instruction: 'Instruction',
      loading: 'Loading',
      attachments: 'Attachments',
      versions: {
        title: 'Versions history',
        noData: 'No history',
        comment: 'Comment:',
        version: 'Version %{version}',
        cancel: 'Cancel',
      },
    },
    articles: {
      noData: 'No instructions',
      searchPlaceholder: 'Title, Description, Tag',
      sort: {
        prefix: 'Sort by',
        [INSTRUCTIONS_CREATED]: {
          helpText: 'Date of creation',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [INSTRUCTIONS_UPDATED]: {
          helpText: 'Update date',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
        [INSTRUCTIONS_NAME]: {
          helpText: 'Title',
          ASC: 'Title ascending',
          DESC: 'Title descending',
        },
      },
      panel: {
        attachments: 'Attachments',
        mainInfo: 'Short description',
      },
    },
    articlesCard: {
      noTitle: 'No title',
      noDescription: 'No description',
      mainInfo: 'Short description',
      sensors: 'Logsource type :',
      tags: 'Tags:',
      detailsModal: 'Details',
      close: 'Close',
      lastUpdate: 'Last update',
      lastUpdateTooltip: 'Last update',
      editTooltip: 'Created',
      created: 'Created',
      name: 'Title',
      more: 'More',
      less: 'Less',
      error: 'Fetching aricle: error',
      mainBlockTitle: 'Aricle info',
      instruction: 'Article',
      loading: 'Loading',
      attachments: 'Attachments',
      versions: {
        title: 'Versions history',
        noData: 'No history',
        comment: 'Comment:',
        version: 'Version %{version}',
        cancel: 'Cancel',
      },
    },
    documents: {
      instructions: 'Instructions\xa0for\xa0setting\xa0up',
      responseInstructions: 'Incident\xa0response\xa0instructions',
      articles: 'Articles',
      noArticles: 'No articles',
      rules: 'Rules',
    },
    documentationCardBase: {
      inAnotherLanguage: 'In another language',
      ruVersion: 'Russian version',
      enVersion: 'English version',
      downloadPDF: 'Download PDF',
    },
    systemsFilter: {
      all: 'Select all',
      only: 'Only',
      cancel: 'Cancel',
      apply: 'Apply',
      placeholder: 'Selected systems',
    },
    sort: {
      buttons: {
        control: {
          tooltip: 'Sorting',
        },
        reset: {
          tooltip: 'Clear fields',
        },
      },
      condition: {
        placeholder: 'Condition',
      },
      direction: {
        placeholder: 'Direction',
      },
    },
    download: {
      buttons: {
        csv: {
          text: 'Export',
          tooltip: 'Export CSV',
        },
      },
      notifications: {
        success: 'The file will come to your email in 5 minutes',
      },
    },
    filterPanel: {
      searchButton: 'Find',
      sortControl: 'Select sort',
      filterButton: 'Filters',
      removeFilter: 'Remove filter',
      rangePicker: {
        format: 'MMMM D, YYYY',
        chooseStart: 'Start date',
        chooseEnd: 'End date',
        last7days: 'Last 7 days',
        last30days: 'Last 30 days',
        thisMonth: 'Current month',
        lastMonth: 'Previous month',
      },
      addFilter: 'Add filter',
      filter: {
        chooseAll: 'Select all',
        notSelected: 'Not selected',
        clearAll: 'Clear all',
        notFound: 'No results found',
        searchPlaceholder: 'Search',
        cancel: 'Cancel',
        apply: 'Apply',
        absent: 'Absent',
        any: 'Any',
        specific: 'Specific',
      },
      incidents: {
        types: {
          [CATEGORY_FIELD]: 'Category',
          [PRIORITY_FIELD]: 'Priority',
          [STATUS_FIELD]: 'Status',
          [CREATE_FIELD]: 'Registered at',
          [SYSTEM_FIELD]: 'Tenant',
          [ACTIVITY_DETECTED]: 'Source',
          [SLA_BREACHED_FIELD]: 'SLA breached',
          [RESOLUTION_DATE_FIELD]: 'Notified at',
          [MITRE_TACTICS]: 'Tactic',
          [MITRE_TECHNIQUES]: 'Technique',
          [SLA_FIELD]: 'SLA',
          [`${AFFECTED_SYSTEMS}_ip`]: 'Host',
          [`${AFFECTED_SYSTEMS}_actor`]: 'Credentials',
          [GOSSOPKA_ID]: NCCCIB ? 'NCCCIB' : 'NCCCI',
          [RULES]: 'Rule',
          [SERVICE_FIELD]: 'Service',
          [RESOLUTION_NAME_FIELD]: 'Resolution',
          [TYPE_FIELD]: 'Issue type',
          [PARSED_ACTIVITY_DETECTED]: 'Events source',
          [CUSTOMER_ASSIGNEE_FIELD]: 'Assigned',
        },
      },
      sources: {
        types: {
          [SYSTEM_FIELD]: 'Tenant',
          [LOGSOURCES_TAGS]: 'Tags',
          [LOGSOURCES_TYPE]: 'Source type',
          [LOGSOURCES_COLLECTOR]: 'Event collector',
          [LOGSOURCES_EPS]: 'Average EPS',
          [LOGSOURCES_LAST_EVENT]: 'Last events',
          [LOGSOURCES_CREATED]: 'First events',
        },
      },
      instructions: {
        types: {
          [INSTRUCTIONS_CREATED]: 'Time registered',
          [INSTRUCTIONS_UPDATED]: 'Time updated',
        },
      },
      ruleList: {
        types: {
          [RULES_SEVERITY]: 'Severity',
          [RULES_CATEGORY]: 'Categories',
          [RULES_CONFIDENCE]: 'Confidence',
          [RULES_CREATED]: 'Created',
          [RULES_TACTIC]: 'MITRE ATT&CK tactics',
          [RULES_TECHNIQUE]: 'MITRE ATT&CK techiques',
          [RULES_ID]: 'Rules',
          availableForService: 'Available for',
          isRequested: 'Custom rule',
          mode: 'Activation mode',
          [RULES_PLATFORM]: 'MITRE ATT&CK platform',
        },
      },
      boolean: {
        true: 'Yes',
        false: 'No',
      },
    },
    dashboardWidgetsMenu: {
      widgets: 'Widgets',
    },
    widget: {
      noData: 'No data availible for selected period',
    },
    incTable: {
      created: 'Registered at',
      updated: 'Updated at',
      id: 'ID',
      socProperties: 'Subject',
      initiator: 'Registered by',
      infoSystem: 'Tenant',
      service: 'Service',
      category: 'Categories',
      priority: 'Priority',
      status: 'Status',
      cardTitle: 'Last incidents',
      search: 'Search',
      pagination: '%{low}-%{high} out of %{total} entries',
    },
    protectedSystems: {
      title: 'Tenants',
      fio: 'Name',
      email: 'Email',
      workPhone: 'Work',
      mobilePhone: 'Mobile',
      slaCategory: 'Level',
      slaHours: 'Value',
      slaValue: {
        isCalendar: '%{hours} working hours',
        notCalendar: '%{hours} astronomical hours',
      },
      mainInfo: 'General information',
      fullName: 'Title',
      id: 'Identifier',
      status: 'Status',
      services: 'Services',
      contract: 'Contract',
      contractField: '%{name} №%{number} (dated %{start})',
      startDate: 'Commencement',
      endDate: 'Contract end date',
      sla: 'SLA',
      contacts: 'Contact references',
      serviceManager: 'Service-manager',
      serviceManagerDouble: 'Stand-in service-manager',
      dedicatedAnalyst: 'Dedicated analyst',
      dedicatedAnalystDouble: 'Stand-in analyst',
      customerContacts: 'Customer contacts',
    },
    createInc: {
      priorityTypes: {
        low: 'Low',
        medium: 'Medium',
        high: 'High',
      },
      categoryTypes: {
        DDoS: 'Denial of service (Dos/DDos)',
        unauthorizedAccessCompromised: ' Illegitimate access',
        scansAttemptedAccess:
          'Intel gathering/ Attempts of illegitimate access',
        bruteForce: 'Brute Force',
        vulnerability: 'Vulnerability',
        defacementDataManipulation: 'Defacement/Data manipulation',
        dataLeak: 'Data Leak',
        malware: 'Malware',
        malwareCCCoumunication: 'Malware C&C Communication',
        maliciousHost: 'Malicious Host',
        phishing: 'Phishing',
        spam: 'Spam',
        fraud: 'Fraud',
        prohibitedContent: 'Prohibited content',
        socialEngineering: 'Social engineering',
        improperUsage: 'Breach of security policy',
        trafficHijacking: 'Traffic hijacking',
        misconfiguration: 'Incorrect configuration of security policy',
        suspiciousProcessActivity: 'Suspicious process activity',
      },
      issueType: {
        label: 'Issue type',
      },
      title: 'Report an incident',
      cancel: 'Cancel',
      submit: 'Submit',
      summary: 'Subject',
      summaryRequired: 'Incident must contain subject',
      description: 'Description of incident',
      descriptionRequired: 'Incident must contain description',
      systemService: 'IT System',
      systemServiceRequired: 'This field is required',
      NCCCIWarning: `The incident with this system will be automatically sent to ${
        NCCCIB ? 'NCCCIB' : 'NCCCI'
      } %{duration} after sending to SOC`,
      priority: 'Priority',
      category: {
        label: 'Category',
        placeholder: 'Choose categories',
        isRequired: 'Category is required',
      },
      attachments: {
        label: 'Attachment',
        button: 'Select a file',
        hint: 'File size is up to 50Mb',
      },
      errors: {
        system: 'IT System: %{error}',
        service: 'Service: %{error}',
      },
      attachmentsError: 'Error adding attachments',
      successMessage:
        'The incident has been successfully registered. Incident ID is %{incKey}',
    },
    reports: {
      filename: 'Title',
      size: 'Size',
      title: 'Reports',
      create: 'Create a report',
      createDate: 'Creation date',
      pagination: '%{low}-%{high} out of %{total} entries',
      time: 'Period',
      system: 'Tenant',
      actions: {
        download: 'Download report',
        edit: 'Create new report with the same options',
        delete: 'Delete report',
        confirmDelete: 'Confirm action',
      },
      reportShedules: 'Shedules',
      groupActions: {
        download: 'Download',
        delete: 'Delete',
        confirmDelete0: 'Delete %{count} report',
        confirmDelete1: 'Delete %{count} reports',
        confirmDelete2: 'Удалить %{count} reports',
      },
      deleteError: 'We encountered an error during fetching your report',
    },
    sheduleCard: {
      editTooltip: 'Created date',
      periodicity: 'Periodicity:',
      startTime: 'Start time:',
      system: 'Tenant:',
      recipients: 'Recipients:',
      editReport: 'Edit report',
    },
    downloadReport: {
      download: 'Download',
    },
    createReport: {
      newReport: 'New report',
      title: 'Create a report',
      save: 'save',
      reportname: 'Title',
      system: 'Tenants',
      type: 'Reporting period',
      placeholders: {
        title: 'Enter title',
        system: 'Select tenants',
        periodicity: 'Select from the list',
        email: 'example1@email.com, example2@email.com',
      },
      requiredTitles: {
        emails: 'Enter correct email addresses',
        reportName: 'Report title is required',
      },
      email: 'Recipient email addresses',
      info: {
        reports: 'Reports will be generated from ',
        oneReport: 'The report will be generated once, for the period from ',
        to: ' to ',
        clarification:
          'Data collection for each report will be made from the moment the previous report is generated until the current one',
        everyDay: 'daily',
        everyWeek: 'weekly',
        twiceAMonth: 'once in two weeks',
        onceAMonth: 'monthly',
      },
      periodicity: {
        label: 'Periodicity',
        everyDay: 'Every day',
        everyWeek: 'Every week',
        twiceAMonth: 'Twice a month',
        onceAMonth: 'Once a month',
        forPeriod: 'For the period',
        startLabel: 'Start date and time',
        none: 'None',
      },
      periodPatternDidNotMatch: 'Invalid date',
      successMessage: 'Your report will be ready in 5 minutes',
      systemsIsRequired: 'Systems list is empty',
      systemsList: 'List of the systems',
      setupReport: 'Report setup',
      noSystems: 'Systems list is empty',
      actions: {
        delete: 'Remove from list',
        confirmDelete: 'Confirm action',
      },
      groupActions: {
        delete: 'Remove from list',
        confirmDelete0: 'Remove from list %{count} system',
        confirmDelete1: 'Remove from list %{count} systems',
        confirmDelete2: 'Remove from list %{count} systems',
      },
      createConfirm: {
        report: 'Report ',
        willBeGenerated: ' will be generated shortly',
        settings: 'Settings for ',
        saved: ' saved',
        deleted: ' deleted',
      },
      createButton: 'Create a report',
      cancelButton: 'Cancel',
      addButton: 'Add systems',
    },
    login: {
      username: 'Login',
      usernameRequired: 'Enter login',
      password: 'Password',
      passwordRequired: 'Enter password',
      submit: 'Sign in',
      pinSent: 'PIN code has been sent to you by SMS, enter PIN code',
      cancel: 'Cancel',
      pinResend: 'Re-request PIN',
      pinResendAccess: 'You can re-request the PIN after ',
    },
    editWidgetMenu: {
      modalTitle: 'Change widget',
      title: 'Title',
      titleRequired: 'Select chart title',
      type: 'Type',
    },
    showRefreshPageNotification: {
      reload: 'Reload',
      message: 'A newer version is available',
      description: 'A newer version is available. Please, reload the page.',
    },
    showNewVersionNotification: {
      message: 'New version',
      description: 'View changelog',
    },
    mitreWidget: {
      title: 'MITRE ATT&CK™',
      helpFrequency: 'Frequency of fixing of technique in incidents',
      critical: 'Very often',
      high: 'Often',
      medium: 'Rare',
      low: 'Very rare',
      none: 'Not fixed',
      hideMitreTechniques:
        'Hide MITRE ATT&CK techniques that have no incidents',
      technique: {
        id: 'ID',
        link: 'link',
        name: 'Name',
        incidents: 'Incidents',
        goToIncs: 'Go to incident list',
        description: 'Description',
      },
    },
    charts: {
      [PRIORITY_FIELD]: 'Priority',
      [CATEGORY_FIELD]: 'Category',
      [CREATE_FIELD]: 'Created date',
      [STATUS_FIELD]: 'Status',
      [SLA_BREACHED_FIELD]: 'SLA',
      [`${SLA_BREACHED_FIELD}Types`]: {
        true: 'Breached',
        false: 'Not breached',
      },
      countIncs: 'Number of incidents',
    },
    duration: {
      millisecond: '%{ms} ms.',
      second: '%{s} sec. %{ms} ms.',
      minute: '%{m} min. %{s} sec.',
      hour: '%{h} h. %{m} min.',
      day: '%{d} d. %{h} h.',
    },
    widgets: {
      priorityWidget: {
        title: 'Priority',
        description: 'Description',
      },
      statusWidget: {
        title: 'Status',
        description: 'Description',
      },
      registrationDynamicsWidget: {
        title: 'Incident registration rate',
        description: 'Description',
      },
      categoryWidget: {
        title: 'Categories',
        description: 'Description',
      },
      slaWidget: {
        title: 'SLA',
        description: 'Description',
      },
      topDomainsWidget: {
        title: 'Top 5 domains',
        description: 'Description',
      },
      topIpAddressesWidget: {
        title: 'Top 5 IP addresses',
        description: 'Description',
      },
      epsWidget: {
        title: 'EPS',
        description: 'Events per second',
        generalBlock: {
          title: 'Time of all exceedances',
        },
        tooltip: {
          averageEps: 'Average EPS',
          excessesPerPeriod: 'Excesses per period',
          excessInfo: 'Excess Information',
          excessDuration: 'Duration',
          maxValue: 'Maximum value',
          duration: {
            y: 'Y.',
            M: 'M',
            S: 'ms.',
            w: 'w.',
            d: 'd.',
            m: 'min.',
            h: 'h.',
            s: 's.',
          },
        },
      },
      widgetTypes: {
        pie: 'Pie chart',
        bar: 'Histogram',
        horizontalBar: 'Horizontal bar graph',
        line: 'Line graph',
        gauge: 'Gauge chart',
      },
    },
    addSystems: {
      title: 'Add systems',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      systems: 'Systems',
      placeholder: 'System name',
      addedSystems: 'Selected systems',
      notAddedSystems: 'Unselected systems',
      multipleSystemsMessage:
        'There would be a separate report for every system',
    },
    downloadNotification: {
      countHeader: 'Downloading %{count} files',
      filenameHeader: 'Downloading %{filename}',
    },
    newIncidentMessage: {
      message: 'Registered new incident: %{key}',
    },
    changePassword: {
      header: 'Change password',
      oldPassword: 'Old password',
      password: 'New password',
      confirm: 'Confirm password',
      mustMatch: 'Must match',
      passwordInvalid: 'Password should be not entirely numeric',
      passwordLengthInvalid: 'Minimum password length is 9',
      success: 'Password has changed',
      cancel: 'Cancel',
      submit: 'Change',
      required: 'This field is required',
    },
    files: {
      downloadButton: 'Download',
      noPreview: 'Preview is not available for this file',
      buttonGoMain: 'Go home',
      buttonGoBack: 'Back',
      notFound: 'File not found',
      error: 'An error occurred while accessing the server',
    },
    ReportsShedules: {
      noData: 'No data',
    },
    ruleList: {
      rules: 'Rules',
      mitre: 'MITRE ATT&CK',
      noData: 'No rules',
      searchPlaceholder: 'Title',
      searchButton: 'Find',
      SortButton: 'Sorting',
      mainInfo: 'General information',
      description: 'Description',
      isRequested: 'Scope',
      properties: 'Properties',
      client: 'Client',
      coverageWidget: {
        title: 'MITRE ATT&CK matrix coverage',
        help: 'MITRE ATT&CK matrix coverage',
      },
      miniDashboard: {
        count: 'Total rules',
        countLastMonth: 'For the last month',
        countIsRequested: 'Custom rules',
      },
      sort: {
        [RULES_CREATED]: {
          helpText: 'Date of creation',
          ASC: 'Oldest to newest',
          DESC: 'Newest to oldest',
        },
      },
      mitreWidget: {
        description: 'Description',
        name: 'Title',
        count: 'Rules',
        tactics: 'Tactics',
        hideNone: 'Hide techniques not covered by the rules',
        tooltip: 'Number of techniques covered by the rules',
      },
    },
    rulePage: {
      mainInfo: 'General information',
      confidence: 'Confidence',
      severity: 'Severity',
      category: 'Categories',
      links: 'References',
      confidenceHelp:
        'The degree of impact that an incident has on the operation of a system',
      severityHelp:
        'Shows how strong the impact on the infrastructure can an incident registered as a result of this rule being triggered',
      description: 'Description',
      caption: 'Caption',
      relatedIncidents: 'Related incidents',
      captionField: 'rule_caption_eng',
      descriptionField: 'description_eng',
      noDescription: 'No description',
      createdTooltip: 'Rule creation date',
      [RULES_CREATED]: 'Rule creation date',
      noLinks: 'No references',
      mitre: 'MITRE ATT&CK',
      platforms: 'Platforms',
      mode: 'Activation mode',
      modeTooltip:
        'Shows how the rule is activated for a newly connected client. Auto - the rule will work automatically if your infrastructure has the required log sources. Manual - the rule is disabled by default, but it can be enabled at your request.',
      activationMode: 'Automatic activation mode',
      isRequested: 'Custom rule',
      isRequestedTooltip:
        'Means that the rule is custom, i.e. was developed at the you request and works only for your infrastructure.',
      availableForServices: 'Available for',
      name: 'Title',
      priorityTypes: {
        low: 'Low',
        medium: 'Medium',
        high: 'High',
      },
      modeTypes: {
        auto: 'Auto',
        manual: 'Manual',
      },
      isRequestedTypes: {
        true: 'Yes',
        false: 'No',
      },
    },
    theme: {
      switch: 'Switch the theme',
    },
  },
};
