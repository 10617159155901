import { BaseSelectOption } from 'combinezone/core';

import { TenantItem } from './types';

export const convertTenantsToOptions = (
  data: TenantItem[],
): BaseSelectOption[] => {
  if (!data.length) {
    return [];
  }

  return data.map(({ id, is_id }) => ({
    value: id.toString(10),
    content: is_id,
    testId: `${is_id}-${id}`,
  }));
};