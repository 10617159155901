import React from 'react';

import {
  SeverityCritical,
  SeverityHigh,
  SeverityLow,
  SeverityMedium,
} from 'combinezone/icons';
import { useTranslate } from 'combinezone/utils';

import locales from '../locales';
import { IncidentPriorityOptions } from '../types';

export const usePriorityOptions = (): IncidentPriorityOptions => {
  const t = useTranslate(locales);
  const testId = 'create-incident-modal-priorities';

  const priorityOptions = [
    {
      content: t('low'),
      value: 'Low',
      leftIcon: <SeverityLow size="24px" />,
      testId: `${testId}-low`,
    },
    {
      content: t('medium'),
      value: 'Medium',
      leftIcon: <SeverityMedium size="24px" />,
      testId: `${testId}-medium`,
    },
    {
      content: t('high'),
      value: 'High',
      leftIcon: <SeverityHigh size="24px" />,
      testId: `${testId}-high`,
    },
  ];

  return { priorityOptions };
};