import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { useDebouncedCallback, useTranslate } from 'combinezone/utils';


import { FormElementWrapper, FormElementError } from '../shared';
import { optionItemWithSpinner } from '../shared/helpers';

import { useTenants } from './hooks/useTenants';
import locales from './locales';
import { TenantSelectFormElementProps } from './types';

const TenantSelectFormElement: FC<TenantSelectFormElementProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const tenantSelectFormElementTestId = `${testId}-tenant-select-form-element`;

  const { data, isFetching, setInlineSearch, setIsOpen } = useTenants();

  const supplementedOptions = useMemo(() => {
    return isFetching ? [optionItemWithSpinner] : data;
  }, [isFetching, data]);

  const tenantSearchHandler = useDebouncedCallback((text: string): void => {
    setInlineSearch(text);
  }, 300);

  return (
    <FormElementWrapper testId={`${tenantSelectFormElementTestId}-wrapper`}>
      <Text testId={`${tenantSelectFormElementTestId}-title`}>
        {t('tenant')}
      </Text>

      <Controller
        name="tenant"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${tenantSelectFormElementTestId}-select`}
            error={!!error}
            multiple={false}
            options={supplementedOptions}
            placeholder={t('tenantPlaceholder')}
            onSearchStringChange={tenantSearchHandler}
            dropdownProps={{
              onOpen: () => setIsOpen(true),
              onClose: () => setIsOpen(false),
            }}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${tenantSelectFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default TenantSelectFormElement;

TenantSelectFormElement.displayName = 'TenantSelectFormElement';