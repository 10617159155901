import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { Header } from '@bizone/ui-bundle/esm/Header';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { List } from '@bizone/ui-bundle/esm/List';
import { Text } from '@bizone/ui-bundle/esm/Text';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { ListSection } from '@common/soc-react-kit';
import { Collapse } from 'antd';

import { KEY_FIELD, INCIDENTS_ENDPOINT, ID_FIELD } from '../../../services/api';
import { incidentFormatter } from '../../../utils/helpers';

import AssessmentBlock from './AssessmentBlock';

import './Panel.scss';
import { ListSectionWithOverflowValue } from 'components/ListSectionWithOverflowValue';

const { mainInfoFormatter } = incidentFormatter;
const allSections = ['summary', 'main', 'mitre', 'ipAndActors', 'rating'];

export class Panel extends React.Component {
  state = {
    isOpen: true,
    opened: allSections,
  };

  onClickAll = () => {
    this.setState({
      opened: this.state.opened.length === 0 ? allSections : [],
    });
  };

  toggleSection = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getIncidentPanelClass = () => {
    const { isOpen } = this.state;
    const { incident } = this.props;

    const incidentPanelClass = ['IncidentListPanel'];
    if (!isOpen) {
      incidentPanelClass.push('IncidentListPanel_closed');
    }
    if (!incident) {
      incidentPanelClass.push('IncidentListPanel_hide');
    }
    return incidentPanelClass.join(' ');
  };

  downloadIncident = () => {
    const { downloadFile, incident = {} } = this.props;
    const id = incident[KEY_FIELD];

    downloadFile(`${INCIDENTS_ENDPOINT}${id}/get_pdf_inc/`, `${id}.pdf`);
  };

  render() {
    const {
      incident = {},
      locale,
      mitreTactics,
      mitreTechniques,
      sendFeedback,
    } = this.props;
    const { isOpen, opened } = this.state;

    const listData = mainInfoFormatter(incident, locale, {
      mitreTactics,
      mitreTechniques,
    });

    const commentProp = I18n.t('incCard.rating.comment');
    const { [commentProp]: comment, ...withoutComment } = listData.rating || {};

    return (
      <div className={this.getIncidentPanelClass()}>
        <div className="IncidentListPanelHeader">
          <IconButton
            basic
            icon="angle-left"
            cls={`PanelIcon${isOpen ? ' PanelIcon_active' : ''}`}
            onClick={this.toggleSection}
          />
          <Header size={16}>{incident[KEY_FIELD]}</Header>
          <div className="IncidentListPanelHeader-Controls">
            <Link target="_blank" to={`/inc/${incident[KEY_FIELD]}`}>
              <IconButton basic icon="launch" />
            </Link>
            <Tooltip
              content={I18n.t(`incidentList.panel.downloadIncident`)}
              delay={200}
              inverted
            >
              <IconButton
                basic
                icon="file-download"
                onClick={this.downloadIncident}
              />
            </Tooltip>
            {isOpen && (
              <IconButton
                basic
                icon="multi-angle-up"
                onClick={this.onClickAll}
                cls={`PanelIcon${
                  opened.length === 0 ? ' PanelIcon_active' : ''
                }`}
              />
            )}
          </div>
        </div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <IconButton
              cls={`PanelIcon${isActive ? ' PanelIcon_active' : ''}`}
              basic
              icon="angle-down"
            />
          )}
          activeKey={opened}
          expandIconPosition="right"
          onChange={(opened) => this.setState({ opened })}
        >
          <Collapse.Panel
            header={<Header size={16}>{I18n.t('incCard.summary')}</Header>}
            key="summary"
          >
            <ListSection width={120} data={listData.sla} />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <Header size={16}>{I18n.t('incCard.mainBlockTitle')}</Header>
            }
            key="main"
          >
            <ListSection
              width={120}
              header={<Translate value="incCard.mainInfo" />}
              data={listData.mainInfo}
            />
            <ListSectionWithOverflowValue
              width={120}
              data={listData.assignRows}
            />
            <ListSection
              width={120}
              header={<Translate value="incCard.details" />}
              data={listData.details}
            />
          </Collapse.Panel>
          <Collapse.Panel
            header={<Header size={16}>{I18n.t('incCard.mitre.title')}</Header>}
            key="mitre"
          >
            {listData.mitre ? (
              <ListSection.Table
                width={120}
                data={listData.mitre}
                titles={[
                  I18n.t('incCard.mitre.tactics'),
                  I18n.t('incCard.mitre.techniques'),
                ]}
              />
            ) : (
              <div className="noData">{I18n.t('incCard.mitre.noData')}</div>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            key="rating"
            header={<Header size={16}>{I18n.t('incCard.rating.title')}</Header>}
          >
            {listData.rating ? (
              <div className="Panel-RatingSection">
                <List data={withoutComment} keysWidth={120} />
                <Text size={14}>{commentProp}</Text>
                <div>{comment}</div>
              </div>
            ) : (
              <AssessmentBlock
                key={incident[ID_FIELD]}
                onSubmit={sendFeedback}
              />
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}
