import * as React from 'react';
import isEqual from 'react-fast-compare';
import { I18n, Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { Header } from '@bizone/ui-bundle/esm/Header';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { ListSection } from '@common/soc-react-kit';
import { Collapse } from 'antd';

import { INSTRUCTIONS_ID, INSTRUCTIONS_NAME } from '../../../services/api';
import { instructionFormatter } from '../../../utils/helpers';

import './Panel.scss';

const { getAttachments, mainInfoFormatter } = instructionFormatter;
const allSections = ['summary', 'attachments'];

export class Panel extends React.Component {
  state = {
    isOpen: true,
    opened: allSections,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  onClickAll = () => {
    this.setState({
      opened: this.state.opened.length === 0 ? allSections : [],
    });
  };

  toggleSection = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getInstructionsPanelClass = () => {
    const { isOpen } = this.state;
    const { instruction } = this.props;

    const incidentPanelClass = ['InstructionsPanel'];
    if (!isOpen) {
      incidentPanelClass.push('InstructionsPanel_closed');
    }
    if (!instruction) {
      incidentPanelClass.push('InstructionsPanel_hide');
    }
    return incidentPanelClass.join(' ');
  };

  downloadPDFHandler = () => {
    const { downloadPDF, instruction = {} } = this.props;

    downloadPDF(instruction[INSTRUCTIONS_ID], instruction[INSTRUCTIONS_NAME]);
  };

  render() {
    const { instruction = {}, locale } = this.props;
    const { isOpen, opened } = this.state;

    const listData = mainInfoFormatter(instruction, locale);

    return instruction[INSTRUCTIONS_NAME] ? (
      <div className={this.getInstructionsPanelClass()}>
        <div className="InstructionsPanelHeader">
          <IconButton
            basic
            icon="angle-left"
            cls={`PanelIcon${isOpen ? ' PanelIcon_active' : ''}`}
            onClick={this.toggleSection}
          />
          <Header size={16}>{instruction[INSTRUCTIONS_NAME]}</Header>
          <div className="InstructionsPanelHeader-Controls">
            <Link to={`/instruction/${instruction[INSTRUCTIONS_ID]}`}>
              <IconButton basic icon="launch" />
            </Link>
            <Tooltip
              content={<Translate value="documentationCardBase.downloadPDF" />}
              delay={200}
              inverted
            >
              <IconButton
                basic
                icon="file-download"
                onClick={this.downloadPDFHandler}
              />
            </Tooltip>
            {isOpen && (
              <IconButton
                basic
                icon="multi-angle-up"
                onClick={this.onClickAll}
                cls={`PanelIcon${
                  opened.length === 0 ? ' PanelIcon_active' : ''
                }`}
              />
            )}
          </div>
        </div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <IconButton
              cls={`PanelIcon${isActive ? ' PanelIcon_active' : ''}`}
              basic
              icon="angle-up"
            />
          )}
          activeKey={opened}
          expandIconPosition="right"
          onChange={(opened) => this.setState({ opened })}
        >
          <Collapse.Panel
            header={
              <Header size={16}>{I18n.t('instructions.panel.mainInfo')}</Header>
            }
            key="summary"
          >
            <ListSection width={120} data={listData.mainInfo} />
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <Header size={16}>
                {I18n.t('instructions.panel.attachments')}
              </Header>
            }
            key="attachments"
          >
            {getAttachments(instruction)}
          </Collapse.Panel>
        </Collapse>
      </div>
    ) : null;
  }
}
