import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { SelectItem } from 'combinezone/core/Select';
import { useDebouncedCallback, useTranslate } from 'combinezone/utils';

import { FormElementWrapper, FormElementError } from '../shared';
import { optionItemWithSpinner } from '../shared/helpers';

import { useAdditionalCategories } from './hooks/useAdditionalCategories';
import locales from './locales';
import { AdditionalCategoriesSelectFormElementProps } from './types';

const AdditionalCategorySelectFormElement: FC<
  AdditionalCategoriesSelectFormElementProps
> = ({ control, error, selectedIssueType, testId }) => {
  const t = useTranslate(locales);
  const additionalCategoriesSelectFormElementTestId = `${testId}-form-element-select-additional-categories`;
  const { data, isFetching, setInlineSearch, setIsOpen, setSelectedIssueType } =
    useAdditionalCategories();
  const [savedData, setSavedData] = useState<SelectItem[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (selectedIssueType) {
      setSelectedIssueType(selectedIssueType.toLowerCase());
    }
  }, [selectedIssueType]);

  const supplementedOptions = useMemo(() => {
    if (isFetching) {
      return [optionItemWithSpinner];
    }

    if (!searchText.length) {
      const allIds = savedData.map((el) => el.value);
      const filteredData = data.filter((el) => !allIds.includes(el.value));
      return [...savedData, ...filteredData];
    }

    return data;
  }, [isFetching, data, searchText, savedData]);

  const additionalCategorySearchHandler = useDebouncedCallback(
    (text: string): void => {
      setInlineSearch(text);
      setSearchText(text);
    },
    300,
  );

  const onChangeHandle = (tasks: string[]): void => {
    const allIds = savedData.map((el) => el.value);
    const filteredSupplementedOptions = supplementedOptions.filter(
      (el) => tasks.includes(el.value) && !allIds.includes(el.value),
    );
    const result = [...savedData, ...filteredSupplementedOptions].filter((el) =>
      tasks.includes(el.value),
    );

    setSavedData(result);
  };

  return (
    <FormElementWrapper
      testId={`${additionalCategoriesSelectFormElementTestId}-wrapper`}
    >
      <Text testId={`${additionalCategoriesSelectFormElementTestId}-title`}>
        {t('additionalCategories')}
      </Text>

      <Controller
        name="additionalCategories"
        control={control}
        rules={{
          required: '',
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${additionalCategoriesSelectFormElementTestId}-select`}
            error={!!error}
            multiple
            options={supplementedOptions}
            placeholder={t('additionalCategoriesPlaceholder')}
            disabled={!selectedIssueType}
            onSearchStringChange={additionalCategorySearchHandler}
            onChange={(val) => {
              field.onChange(val);
              onChangeHandle(val);
            }}
            dropdownProps={{
              onOpen: () => setIsOpen(true),
              onClose: () => setIsOpen(false),
            }}
          />
        )}
      />

      {error && (
        <FormElementError
          testId={`${additionalCategoriesSelectFormElementTestId}-error`}
        >
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default AdditionalCategorySelectFormElement;

AdditionalCategorySelectFormElement.displayName =
  'AdditionalCategorySelectFormElement';
