export default {
  addingInc: 'Добавление инцидента',
  openModalButton: 'Инцидент',
  createIncident: 'Добавление инцидента',
  createButton: 'Добавить',
  cancelButton: 'Отмена',
  createSuccessPartOne: 'Инцидент',
  createSuccessPartTwo: 'добавлен',
  createError: 'При добавлении произошли проблемы',
  createSuccessAttach: 'Файлы добавлены',
};