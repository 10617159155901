import React, { FC, memo } from 'react';

import { getDateInPartialDateAndTimeFormat } from '@soc/kit/date';
import { Text } from 'combinezone/core';
import { useLanguage } from 'combinezone/utils';

type CellProps = {
  testId: string;
  date: string;
};

const Cell: FC<CellProps> = ({ date, testId }) => {
  const { language } = useLanguage();

  return (
    <Text testId={`${testId}-text`} isClipped>
      {date ? getDateInPartialDateAndTimeFormat(language, date) : ''}
    </Text>
  );
};

export default memo(Cell);
