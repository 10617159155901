import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { IconButton } from '@bizone/ui-bundle/esm/IconButton';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { ListPage, ListSection } from '@common/soc-react-kit';
import { Alert } from 'antd';
import { useLanguage } from 'combinezone/utils';

import {
  INSTRUCTIONS_CREATED,
  INSTRUCTIONS_UPDATED,
  INSTRUCTIONS_NAME,
  INSTRUCTIONS_ID,
  ARTICLES_ENTITY,
} from '../../services/api';
import {
  instructionFormatter,
  setFilterFromQuerysringGeneral,
} from '../../utils/helpers';

import { Card } from './Card';

export default function Articles({
  articles,
  currentLocale,
  downloadPDF,
  fetchMore,
  filters,
  firstFetch,
  hasMore,
  isFetching,
  localSaveFilters,
  onChangeSearch,
  ordering,
  resetFilters,
  search,
  setFilter,
  setInitFilter,
  setInitSearch,
  setInitSort,
  setSort,
}) {
  const { language } = useLanguage();
  const [selected, setSelected] = useState();
  const history = useHistory();
  const { pathname, search: querystring } = useLocation();

  const cardRenderer = useCallback(
    ({ entity, isSelected, select }) => (
      <Card
        key={entity.id}
        entity={entity}
        select={select}
        isSelected={isSelected}
        language={language}
      />
    ),
    [],
  );

  useEffect(() => {
    setFilterFromQuerysringGeneral({
      querystring,
      setFilter: setInitFilter,
      setSort: setInitSort,
      setSearch: setInitSearch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localSaveFilters({ entity: ARTICLES_ENTITY, history, pathname });
  }, [
    localSaveFilters,
    history,
    pathname,
    filters,
    search,
    currentLocale,
    ordering,
  ]);

  useEffect(() => {
    firstFetch();
  }, [firstFetch, currentLocale]);

  useEffect(() => {
    if (!selected && articles.length) {
      setSelected(articles[0]);
    }
  }, [articles, selected]);

  const config = useMemo(
    () => ({
      listConfig: {
        hasMore,
        fetchMore,
        data: articles,
        selected,
        select: setSelected,
        isFetching,
        noData: <Translate value="articles.noData" />,
        cardRenderer,
      },
      panelConfig: {
        title: ({ [INSTRUCTIONS_NAME]: name }) => name,
        controls: [
          (entity) => (
            <React.Fragment>
              <Link to={`/article/${entity ? entity[INSTRUCTIONS_ID] : null}`}>
                <IconButton basic icon="launch" />
              </Link>
              <Tooltip
                content={
                  <Translate value="documentationCardBase.downloadPDF" />
                }
                delay={200}
                inverted
              >
                <IconButton
                  basic
                  icon="file-download"
                  onClick={() =>
                    downloadPDF(
                      entity[INSTRUCTIONS_ID],
                      entity[INSTRUCTIONS_NAME],
                    )
                  }
                />
              </Tooltip>
            </React.Fragment>
          ),
        ],
        sections: {
          summary: {
            title: I18n.t('instructions.panel.mainInfo'),
            renderer: (article) => (
              <Summary article={article} locale={currentLocale} />
            ),
          },
          attachments: {
            title: I18n.t('instructions.panel.attachments'),
            renderer: (article) => <Attachments article={article} />,
          },
        },
      },
      headerConfig: {
        searchConfig: {
          search,
          onChangeSearch,
          placeholder: I18n.t('articles.searchPlaceholder'),
        },
        filtersConfig: {
          settings: [INSTRUCTIONS_CREATED, INSTRUCTIONS_UPDATED].reduce(
            (obj, key) => {
              obj[key] = {
                label: (
                  <Translate value={`filterPanel.instructions.types.${key}`} />
                ),
                type: 'date',
              };
              return obj;
            },
            {},
          ),
          setFilter,
          resetFilters,
          filters,
          currentLocale,
        },
        sortConfig: {
          settings: [
            INSTRUCTIONS_CREATED,
            INSTRUCTIONS_UPDATED,
            INSTRUCTIONS_NAME,
          ].reduce((obj, key) => {
            obj[key] = {
              label: I18n.t(`instructions.sort.${key}.helpText`),
              DESC: I18n.t(`instructions.sort.${key}.DESC`),
              ASC: I18n.t(`instructions.sort.${key}.ASC`),
            };
            return obj;
          }, {}),
          setSort,
          ordering,
        },
      },
    }),
    [
      hasMore,
      fetchMore,
      articles,
      selected,
      isFetching,
      cardRenderer,
      search,
      onChangeSearch,
      setFilter,
      resetFilters,
      filters,
      currentLocale,
      setSort,
      ordering,
      downloadPDF,
    ],
  );

  return (
    <>
      {articles?.length === 0 && !isFetching && (
        <Alert
          message={I18n.t('instructions.noDataForSelectedLanguage')}
          type="info"
          showIcon
          closable
        />
      )}
      <ListPage {...config} />
    </>
  );
}

// TODO: оптимизировать как в ucp
function Summary({ article = {}, locale }) {
  const { mainInfoFormatter } = instructionFormatter;
  const listData = mainInfoFormatter(article, locale);
  return <ListSection width={120} data={listData.mainInfo} />;
}

function Attachments({ article = {} }) {
  const { getAttachments } = instructionFormatter;
  return getAttachments(article);
}
