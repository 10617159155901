import React, { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import styled from 'styled-components';

import { Button, ButtonPrimary, Flex, Modal } from 'combinezone/core';
import { ListCreate } from 'combinezone/icons';
import { useTranslate } from 'combinezone/utils';


import AdditionalCategorySelectFormElement from './components/AdditionalCategoriesSelectFormElement';
import DescriptionWisiwygFormElement from './components/DescriptionWisiwygFormElement';
import ImagesLoader from './components/ImagesLoader';
import IssueTypeSelectFormElement from './components/IssueTypeSelectFormElement';
import MainCategorySelectFormElement from './components/MainCategorySelectFormElement';
import PrioritySelectFormElement from './components/PrioritySelectFormElement';
import SubjectInputFormElement from './components/SubjectInputFormElement';
import TenantSelectFormElement from './components/TenantSelectFormElement';
import { useCreateIncident } from './hooks/useCreateIncident';
import { useCreateIncidentAttach } from './hooks/useCreateIncidentAttach';
import locales from './locales';
import {
  CreateIncModalForm,
  SetNewIncidentAttachProps,
  SetNewIncidentProps,
} from './types';

const TemplateForm = styled.form`
  width: 100% !important;
`;

const CreateIncModal: FC = () => {
  const t = useTranslate(locales);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = (): void => setIsOpen(false);
  const onOpen = (): void => setIsOpen(true);

  const [incidentKey, setIncidentKey] = useState<string>('');
  const [files, setFiles] = useState<File[]>();
  const createIncModalTestId = `modal-create-inc`;
  const values = {
    issueType: '',
    subject: '',
    description: '',
    tenant: '',
    mainCategory: '',
    additionalCategories: [],
    priority: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
    setError,
    watch,
  } = useForm<CreateIncModalForm>({ values });
  const selectedIssueType = watch('issueType');

  useEffect(() => {
    resetField('mainCategory');
    resetField('additionalCategories');
  }, [selectedIssueType]);


  const {
    isFetching: isFetchingCreateIncident,
    setIsClose,
    setNewIncident,
  } = useCreateIncident({
    onClose,
    setIncidentKey,
    setError,
  });

  const { isFetching: isFetchingCreateIncidentAttach, setNewIncidentAttach } =
    useCreateIncidentAttach({
      onClose,
    });


  const onCloseClickHandler = (): void => {
    if (!isFetchingCreateIncident && !isFetchingCreateIncidentAttach) {
      onClose();
    }
  };

  const onSubmit: SubmitHandler<CreateIncModalForm> = (formData): void => {
    const incident: SetNewIncidentProps = {
      ...formData,
    };

    setNewIncident(incident);
  };

  const onSubmitClickHandler = async (): Promise<void> => {
    await handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (files?.length) {
      setIsClose(false);
    }
  }, [files, setIsClose]);

  useEffect(() => {
    if (incidentKey && files?.length) {

      const incidentAttach: SetNewIncidentAttachProps = {
        incidentKey,
        attach: files,
      };
      setNewIncidentAttach(incidentAttach);
    }
  }, [incidentKey, files]);

  const content = useMemo(() => {
    return (
      <TemplateForm onSubmit={handleSubmit(onSubmit)}>
        <Flex gap="24px" direction="column">
          <IssueTypeSelectFormElement
            control={control}
            error={errors.issueType}
            testId={createIncModalTestId}
          />
          <SubjectInputFormElement
            control={control}
            error={errors.subject}
            testId={createIncModalTestId}
          />
          <DescriptionWisiwygFormElement
            control={control}
            error={errors.description}
            testId={createIncModalTestId}
          />
          <TenantSelectFormElement
            control={control}
            error={errors.tenant}
            testId={createIncModalTestId}
          />
          <MainCategorySelectFormElement
            testId={createIncModalTestId}
            control={control}
            error={errors.mainCategory}
            selectedIssueType={selectedIssueType}
          />

          <AdditionalCategorySelectFormElement
            testId={createIncModalTestId}
            control={control}
            error={errors.additionalCategories}
            selectedIssueType={selectedIssueType}
          />
          <PrioritySelectFormElement
            control={control}
            error={errors.priority}
            testId={createIncModalTestId}
          />
          <ImagesLoader
            testId={createIncModalTestId}
            setFileValues={setFiles}
          />
        </Flex>
      </TemplateForm>
    );
  }, [handleSubmit, onSubmit, control, createIncModalTestId]);


  const footerContent = (
    <Flex gap="10px">
      <ButtonPrimary
        testId={`${createIncModalTestId}-button-create`}
        onClick={onSubmitClickHandler}
        isLoading={isFetchingCreateIncident || isFetchingCreateIncident}
      >
        {t('createButton')}
      </ButtonPrimary>
      <Button
        testId={`${createIncModalTestId}-button-close`}
        onClick={onCloseClickHandler}
        isLoading={isFetchingCreateIncident || isFetchingCreateIncident}
      >
        {t('cancelButton')}
      </Button>
    </Flex>
  );

  return (
    <>
      <Button
        testId={`${createIncModalTestId}-button-open-modal`}
        variant="transparentWithBorder"
        LeftIcon={ListCreate}
        className="Button"
        onClick={() => onOpen()}
      >
        {t('openModalButton')}
      </Button>
      {isOpen && (
        <Modal
          testId={createIncModalTestId}
          title={t('addingInc')}
          content={content}
          footerContent={footerContent}
          onClose={onCloseClickHandler}
          focusLockProps={{
            disabled: true,
          }}
        />
      )}
      {!isOpen && null}
    </>
  );
};
export default CreateIncModal;

CreateIncModal.displayName = 'CreateIncModal';