export const handleRequestItem = (
  item: number | null | undefined,
): string | number => {
  if (item === null) {
    return '—';
  }

  if (typeof item === 'number') {
    return item;
  }

  return '—';
};
