import { api } from '@soc/kit/api';
import { SOC_URL } from '@soc/kit/api/constants';

import {
  additionalCategoriesFetchAbortControllerKey,
  additionalCategoryFetchLimit,
} from '../constants';
import { AdditionalCategoriesFetch, AdditionalCategoriesItem } from '../types';

export const fetchAdditionalCategories: AdditionalCategoriesFetch = ({
  issueType,
  search,
}) => {
  const trimmedSearch = search.trim();
  const apiSearch = trimmedSearch ? `&q=id ~ "${trimmedSearch}"` : '';

  return api<AdditionalCategoriesItem[]>(
    `${SOC_URL}issues/${issueType}/categories/?limit=${additionalCategoryFetchLimit}${apiSearch}`,
    {
      method: 'get',
      abortControllerKey: additionalCategoriesFetchAbortControllerKey,
      isMainInstallation: true,
    },
  );
};
