import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert } from 'antd';
import { Text } from 'combinezone/core';

import { useNewFilters } from 'components/RuleListPage/hooks';
import MitreWidget from 'components/RulesMitreWidget';

import MiniDashboard from '../../containers/RuleListContainer-MiniDashboard';
import {
  RULES_TACTIC,
  RULES_TECHNIQUE,
  RULES_CREATED,
  RULES_ENTITY,
  RULES_PLATFORM,
} from '../../services/api';
import { setFilterFromQuerystringRules } from '../../utils/helpers';
import { ListPage } from '../ListPage';
import Card from '../RuleCard';
import SearchPanel from '../SearchPanel';

import { usePanelConfig } from './usePanelConfig';

const RULES_TAB = 'rules';
const MITRE_TAB = 'mitre';

export default function RuleList({
  currentLocale,
  fetchAnalytics,
  fetchAnalyticsMitre,
  fetchMitreMapping,
  fetchMore,
  filters,
  firstFetch,
  hasMore,
  isFetching,
  localSaveFilters,
  mapping,
  onChangeSearch,
  ordering,
  platforms,
  resetFilters,
  rules,
  search,
  setFilter,
  setInitFilter,
  setInitSearch,
  setInitSort,
  setSort,
  settings,
  tactics,
  techniques,
}) {
  const history = useHistory();
  const { pathname, search: querystring } = useLocation();
  const [selected, setSelected] = useState();
  const [tab, setTab] = useState(RULES_TAB);
  const panelConfig = usePanelConfig(tab, tactics, techniques, mapping);

  const cardRenderer = useCallback(
    ({ entity, isSelected, listWidth, select }) => (
      <div key={entity.id}>
        <Card
          cardListWidth={listWidth}
          entity={entity}
          select={select}
          isSelected={isSelected}
          currentLocale={currentLocale}
        />
      </div>
    ),
    [currentLocale],
  );

  useEffect(() => {
    setFilterFromQuerystringRules({
      querystring,
      setFilter: setInitFilter,
      setSort: setInitSort,
      setSearch: setInitSearch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localSaveFilters({ entity: RULES_ENTITY, history, pathname });
  }, [
    localSaveFilters,
    history,
    pathname,
    filters,
    search,
    currentLocale,
    ordering,
  ]);

  useEffect(() => {
    if (tab === RULES_TAB) {
      firstFetch();
      fetchMitreMapping();
      fetchAnalytics();
    }
  }, [
    firstFetch,
    fetchMitreMapping,
    currentLocale,
    fetchAnalytics,
    fetchAnalyticsMitre,
    tab,
  ]);

  useEffect(() => {
    if (!selected && rules.length) {
      setSelected(rules[0]);
    }
  }, [rules, selected]);

  const buttonGroupItems = useMemo(
    () =>
      [
        {
          text: <Translate value="ruleList.rules" />,
          id: RULES_TAB,
        },
        {
          text: <Translate value="ruleList.mitre" />,
          id: MITRE_TAB,
        },
      ].map(({ id, text }) => ({
        testId: `ButtonGroupItem_${id}`,
        content: <Text className={id}>{text}</Text>,
        isActive: tab === id,
        onClick: () => setTab(id),
        className: 'MenuButton',
      })),
    [tab],
  );

  const newFilters = useNewFilters();

  const searchPanelSettings = useMemo(() => {
    const tacticsCoverage = Object.entries(tactics).reduce(
      (result, [key, value]) => {
        if (value.is_hidden) {
          return result;
        }

        result[key] = value;
        return result;
      },
      {},
    );

    return {
      ...settings,
      [RULES_TECHNIQUE]: {
        label: I18n.t(`filterPanel.ruleList.types.${RULES_TECHNIQUE}`),
        type: 'multiselect',
        choices: Object.values(techniques).map(
          ({ name, technique_id: key }) => ({
            key,
            text: `${key}: ${name}`,
          }),
        ),
      },
      [RULES_TACTIC]: {
        label: I18n.t(`filterPanel.ruleList.types.${RULES_TACTIC}`),
        type: 'multiselect',
        choices: Object.values(tacticsCoverage).map(
          ({ name: text, short_name: key }) => ({ key, text }),
        ),
      },
      [RULES_PLATFORM]: {
        label: I18n.t(`filterPanel.ruleList.types.${RULES_PLATFORM}`),
        type: 'multiselect',
        choices: platforms.map((platform) => ({
          key: platform,
          text: platform,
        })),
      },
      ...newFilters,
    };
  }, [settings, techniques, tactics, currentLocale, newFilters]);

  const sortConfig = useMemo(
    () => ({
      isLoading: isFetching,
      sortList: [RULES_CREATED].reduce((obj, key) => {
        obj[key] = {
          label: I18n.t(`ruleList.sort.${key}.helpText`),
          DESC: I18n.t(`ruleList.sort.${key}.DESC`),
          ASC: I18n.t(`ruleList.sort.${key}.ASC`),
        };
        return obj;
      }, {}),
      setSort,
      ordering,
    }),
    [isFetching, setSort, ordering, currentLocale],
  );

  const searchConfig = useMemo(
    () => ({
      isLoading: isFetching,
      defaultSearch: search,
      onChangeSearch,
      placeholder: I18n.t('ruleList.searchPlaceholder'),
    }),
    [isFetching, search, onChangeSearch, currentLocale],
  );

  const config = useMemo(
    () => ({
      className: 'RuleCards',
      listConfig: {
        renderer: tab === MITRE_TAB ? () => <MitreWidget /> : undefined,
        hasMore,
        fetchMore,
        data: rules,
        selected,
        select: setSelected,
        isFetching,
        observeListWidth: true,
        noData: <Translate value="ruleList.noData" />,
        cardRenderer,
      },
      panelConfig,
      headerConfig: {
        renderer: () => (
          <>
            <SearchPanel
              isLoading={isFetching}
              settings={searchPanelSettings}
              setFilter={setFilter}
              resetFilters={resetFilters}
              filters={filters}
              currentLocale={currentLocale}
              sortConfig={sortConfig}
              searchConfig={searchConfig}
              buttonGroupItems={buttonGroupItems}
            />
            {tab === 'rules' && <MiniDashboard />}
          </>
        ),
      },
    }),
    [
      hasMore,
      fetchMore,
      rules,
      selected,
      isFetching,
      cardRenderer,
      search,
      onChangeSearch,
      setFilter,
      resetFilters,
      filters,
      currentLocale,
      settings,
      techniques,
      tactics,
      setSort,
      ordering,
      panelConfig,
      tab,
    ],
  );

  return (
    <>
      {rules?.length === 0 && !isFetching && (
        <Alert
          message={I18n.t('instructions.noDataForSelectedLanguage')}
          type="info"
          showIcon
          closable
        />
      )}
      <ListPage {...config} />
    </>
  );
}
