
export default {
  addingInc: 'Add incident',
  openModalButton: 'Incident',
  createIncident: 'Add incident',
  createButton: 'Add',
  cancelButton: 'Cancel',
  createSuccessPartOne: 'Incident',
  createSuccessPartTwo: 'added',
  createError: 'Problems occurred while adding',
  createSuccessAttach: 'Files added',
};