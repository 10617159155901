import { api } from '@soc/kit/api';
import { SOC_URL } from '@soc/kit/api/constants';


import { getFormBodyFromJson } from 'utils/helpers';

import { incidentCreateFetchAbortControllerKey } from '../constants';
import { FetchIncidentAttach } from '../types';

export const fetchCreateIncidentAttach: FetchIncidentAttach = ({
  attach,
  incidentKey,
}) => {
  const formData = getFormBodyFromJson({
    attachments: attach,
  });

  return api<unknown>(`${SOC_URL}incs/${incidentKey}/add_file/`, {
    method: 'post',
    body: formData,
    abortControllerKey: incidentCreateFetchAbortControllerKey,
    isApplicationJson: false,
    isMainInstallation: true,
  });
};