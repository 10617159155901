import React, { useMemo } from 'react';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { IconButton } from '@bizone/ui-bundle/esm/IconButton';

import { ListSection } from '@common/soc-react-kit';
import { getDateInFullDateAndTimeFormat } from '@soc/kit/date';
import { Badge, Flex, Spacer, Text } from 'combinezone/core';
import { CircleAutoupdate, UserEditor } from 'combinezone/icons';

import { SectionSensors } from 'components/RuleListPage/Panel';
import { useTheme } from 'utils/brending';

import { MITRE_FIELD, RULES_CREATED } from '../../services/api';
import { currentLocale } from '../../store/reducers';
import { priorityIconProps, tooLongStringFormatter } from '../../utils/helpers';
import { ReadMitre } from '../ReadMitre';
import MitreWidgetPanel from '../RulesMitreWidget/MitreWidgetPanel';

const Container = styled.div`
  padding: 16px;
`;

const testId = 'panel';

export const usePanelConfig = (
  tab = 'rulles',
  tactics,
  techniques,
  mapping,
  selectedTechnique,
) => {
  return tab !== 'mitre'
    ? {
        title: (rule) => tooLongStringFormatter(rule.rule_id, 25),
        controls: (entity) => (
          <Link target="_blank" to={`/rule/${entity ? entity.id : null}`}>
            <IconButton basic icon="launch" />
          </Link>
        ),
        content: {
          summary: {
            title: I18n.t('ruleList.mainInfo'),
            renderer: (rule = {}) => <RulesSummary rule={rule} />,
          },
          descriptionField: {
            title: I18n.t('ruleList.description'),
            visible: (rule = {}) => rule[I18n.t('rulePage.descriptionField')],
            renderer: (rule = {}) => (
              <Container>{rule[I18n.t('rulePage.descriptionField')]}</Container>
            ),
          },
          properties: {
            title: I18n.t('ruleList.properties'),
            renderer: (rule = {}) => (
              <Container>
                <RulesProperties rule={rule} />
              </Container>
            ),
          },
          mitre: {
            title: I18n.t('rulePage.mitre'),
            visible: (rule = {}) =>
              rule[MITRE_FIELD] && Object.keys(rule[MITRE_FIELD]).length > 0,
            renderer: (rule = {}) => (
              <Container>
                <ReadMitre
                  withLinks
                  value={rule[MITRE_FIELD]}
                  mitre={{
                    techniques,
                    tactics,
                    mitreMapping: mapping,
                  }}
                />
              </Container>
            ),
          },
          sensors: {
            title: I18n.t('rulePage.sensors'),
            visible: (rule = {}) => rule,
            renderer: (rule = {}) => (
              <Container>
                <SectionSensors id={rule.id} testId={testId} />
              </Container>
            ),
          },
          links: {
            title: I18n.t('rulePage.links'),
            visible: (rule = {}) => rule.links,
            renderer: (rule = {}) => (
              <Container>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {rule.links}
                </Linkify>
              </Container>
            ),
          },
        },
      }
    : {
        renderer: () => <MitreWidgetPanel />,
      };
};

export function RulesSummary({ rule = {} }) {
  const locale = useSelector(currentLocale);
  const {
    basis: { colors },
  } = useTheme();

  const isRequested = rule.is_requested
    ? {
        [I18n.t('ruleList.isRequested')]: {
          text: (
            <div className="RowWithIcon">
              <UserEditor color="#EBAF09" />
              <Spacer width={4} />
              <Translate value="ruleList.client" />
            </div>
          ),
          help: I18n.t('rulePage.isRequestedTooltip'),
        },
      }
    : {};
  const mainInfo = useMemo(
    () =>
      rule
        ? {
            [I18n.t('rulePage.name')]: rule[I18n.t('rulePage.captionField')],
            [I18n.t('rulePage.created')]: getDateInFullDateAndTimeFormat(
              locale,
              rule[RULES_CREATED],
            ),
            ...isRequested,
            [I18n.t('rulePage.confidence')]: {
              text: (
                <div className="RowWithIcon">
                  <Icon
                    size={24}
                    {...priorityIconProps[rule.confidence?.toLowerCase()]}
                  />
                  {I18n.t(
                    `rulePage.priorityTypes.${rule.confidence?.toLowerCase()}`,
                  )}
                </div>
              ),
              help: I18n.t('rulePage.confidenceHelp'),
            },
            [I18n.t('rulePage.severity')]: {
              text: (
                <div className="RowWithIcon">
                  <Icon
                    size={24}
                    {...priorityIconProps[rule.severity?.toLowerCase()]}
                  />
                  {I18n.t(
                    `rulePage.priorityTypes.${rule.severity?.toLowerCase()}`,
                  )}
                </div>
              ),
              help: I18n.t('rulePage.severityHelp'),
            },
            [I18n.t('rulePage.mode')]: {
              text: (
                <div className="RowWithIcon">
                  <CircleAutoupdate color={colors.link.normal} />
                  <Spacer width={4} />
                  <Translate
                    value={`rulePage.modeTypes.${rule.activation_mode}`}
                  />
                </div>
              ),
              help: I18n.t('rulePage.modeTooltip'),
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }
        : '',
    [locale, rule],
  );

  return <ListSection width={150} data={mainInfo} />;
}

export function RulesProperties({ rule = {} }) {
  return (
    <div>
      <Text variant="secondary">
        <Translate value="rulePage.availableForServices" />
      </Text>
      <Spacer height={8} />
      <Flex className="FlexWrap">
        {rule.available_for_services?.map((service, i) => (
          <>
            <Badge accent="transparent" key={service}>
              {service}
            </Badge>
            <Spacer width={4} />
          </>
        ))}
      </Flex>

      <Spacer height={16} />

      <Text variant="secondary">
        <Translate value="rulePage.category" />
      </Text>
      <Spacer height={8} />
      <Flex className="FlexWrap">
        {rule.category?.map((service, i) => (
          <Badge accent="transparent" key={service}>
            {service}
          </Badge>
        ))}
      </Flex>
    </div>
  );
}
