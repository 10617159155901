import React, { FC, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './RuleCard.scss';
import { getDateInFullDateAndTimeFormat } from '@soc/kit/date';
import { Flex } from 'combinezone/core';
import {
  SeverityMedium,
  SeverityHigh,
  AngleAllUp,
  SeverityLow,
} from 'combinezone/icons';

import { RULES_CREATED } from '../../services/api';
import { getMitreTechniques } from '../../store/reducers';

import Availability from './Availability';
import Categories from './Categories';
import Header from './Header';
import Icons from './Icons';
import Levels from './Levels';
import Mitre from './Mitre';
import { isNeedTooltip, transformMitre } from './helpers';
import { RuleCardProps, IconsSeverityTypes } from './types';

const iconsSeverity: IconsSeverityTypes = {
  Critical: <AngleAllUp size="24px" color="#e0281b" />,
  High: <SeverityHigh size="24px" color="#eb6f1c" />,
  Medium: <SeverityMedium size="24px" color="#ebaf09" />,
  Low: <SeverityLow size="24px" color="#57ad37" />,
};

const RuleCard: FC<RuleCardProps> = ({
  cardListWidth = 100,
  currentLocale,
  entity,
  isSelected,
  select,
}) => {
  const cardWidth =
    cardListWidth < 900 ? '-Small' : cardListWidth < 1080 ? '-Medium' : '';
  const allTechniques = useSelector(getMitreTechniques);

  const headerProps = useMemo(
    () => ({
      id: entity.id,
      idRule: entity.rule_id,
      caption: entity[I18n.t('rulePage.captionField')],
      captionTooltip: isNeedTooltip({
        text: entity[I18n.t('rulePage.captionField')],
        maxLength: (cardListWidth * 0.8) / 11,
      }),
      createdDate: getDateInFullDateAndTimeFormat(
        currentLocale,
        entity[RULES_CREATED],
      ),
    }),
    [entity, currentLocale],
  );

  const levelsProps = useMemo(
    () => ({
      iconConfidence: iconsSeverity[entity.confidence],
      confidence: entity.confidence?.toLowerCase(),
      iconSeverity: iconsSeverity[entity.severity],
      severity: entity.severity?.toLowerCase(),
      isShort: !!cardWidth,
    }),
    [entity, cardWidth],
  );

  const categories = useMemo(() => {
    const categories = entity.category || [];
    if (categories.length > 2) {
      const shortCategories = categories.slice(0, 3);
      categories.length !== 3 &&
        shortCategories.push(`+${categories.length - 3}`);
      return shortCategories;
    }
    return categories;
  }, [entity]);

  const mitres = useMemo(
    () => transformMitre({ mitre: entity.mitre_cov, allTechniques }),
    [entity, allTechniques],
  );

  return (
    <div
      className={`RuleCard ${isSelected ? 'RuleCard-Selected' : ''}`}
      onClick={isSelected ? undefined : () => select(entity)}
    >
      <Icons
        activationMode={entity.activation_mode}
        isRequested={entity.is_requested}
      />

      <div className="Main">
        <Header {...headerProps} />

        <Flex className="Body">
          <div className={`Levels ${cardWidth ? `Levels-Short` : ''}`}>
            <Levels {...levelsProps} />
          </div>

          <div
            className={`Availables ${
              cardWidth ? `Availables${cardWidth}` : ''
            }`}
          >
            <Availability
              id={entity.id}
              available={entity.available_for_services}
            />
          </div>

          <Categories
            id={entity.id}
            categories={categories}
            isNeedTooltip={isNeedTooltip}
          />

          <Mitre id={entity.id} mitres={mitres} isNeedTooltip={isNeedTooltip} />
        </Flex>
      </div>
    </div>
  );
};

export default memo(RuleCard);
