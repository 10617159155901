import styled from 'styled-components';

import { Flex, Text } from 'combinezone/core';
import { CombinezoneTheme } from 'combinezone/theme';

export const FormElementWrapper = styled(Flex)`
  gap: 8px;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`;

export const FormElementError = styled(Text)`
  font-size: 14px;
  color: ${({ theme }: { theme: CombinezoneTheme }) =>
    theme.components.input.colors.border.critical};
`;