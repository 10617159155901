import { api } from '@soc/kit/api';
import { SOC_URL } from '@soc/kit/api/constants';

import {
  mainCategoryFetchAbortControllerKey,
  mainCategoryFetchLimit,
} from '../constants';
import { MainCategoriesFetch, MainCategoriesItem } from '../types';

export const fetchMainCategories: MainCategoriesFetch = ({
  issueType,
  search,
}) => {
  const trimmedSearch = search.trim();
  const apiSearch = trimmedSearch ? `&q=id ~ "${trimmedSearch}"` : '';

  return api<MainCategoriesItem[]>(
    `${SOC_URL}issues/${issueType}/categories/?limit=${mainCategoryFetchLimit}${apiSearch}`,
    {
      method: 'get',
      abortControllerKey: mainCategoryFetchAbortControllerKey,
      isMainInstallation: true,
    },
  );
};
