import * as React from 'react';
import isEqual from 'react-fast-compare';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import { Header } from '@bizone/ui-bundle/esm/Header';
import { Icon } from '@bizone/ui-bundle/esm/Icon';
import { List } from '@bizone/ui-bundle/esm/List';
import { Tooltip } from '@bizone/ui-bundle/esm/Tooltip';

import { getDateInFullDateAndTimeFormat } from '@soc/kit/date';

import {
  INSTRUCTIONS_ID,
  INSTRUCTIONS_CREATED,
  INSTRUCTIONS_UPDATED,
  INSTRUCTIONS_NAME,
} from '../../../services/api';
import { instructionFormatter } from '../../../utils/helpers';

const { getTags } = instructionFormatter;

const cls = 'InstructionsCard';
export const Card = React.memo(
  ({ entity, isSelected, language, select }) => (
    <div
      className={`${cls} ${isSelected ? `${cls}_selected` : ''}`}
      onClick={() => (isSelected ? undefined : select(entity))}
    >
      <div className="InstructionsCard-Header" style={{ position: 'relative' }}>
        <Header size={14}>
          <Link to={`/responseInstruction/${entity[INSTRUCTIONS_ID]}`}>
            {entity[INSTRUCTIONS_NAME]}
          </Link>
        </Header>
        <div className="InstructionsCard-Date">
          <Tooltip inverted content={I18n.t('incCard.editTooltip')}>
            <div>
              <Icon glyph="edit" size={14} />
              {getDateInFullDateAndTimeFormat(
                language,
                entity[INSTRUCTIONS_CREATED],
              )}
            </div>
          </Tooltip>
          <Tooltip inverted content={I18n.t('incCard.lastUpdateTooltip')}>
            <div>
              <Icon glyph="refresh" size={14} />
              {getDateInFullDateAndTimeFormat(
                language,
                entity[INSTRUCTIONS_UPDATED],
              )}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="InstructionsCard-Body">
        <List
          data={{
            [I18n.t('instructionsCard.tags')]: getTags(entity, true),
          }}
          cls="InstructionsCard-List"
          keysWidth={40}
        />
      </div>
    </div>
  ),
  isEqual,
);
