import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { FormElementWrapper, FormElementError } from '../shared';

import locales from './locales';
import { IssueTypeSelectFormElementProps } from './types';


const issueTypeOptions = [
  { value: 'Incident', content: 'Incident', testId: 'Incident'  },
  { value: 'Request', content: 'Request', testId: 'Incident' },
  { value: 'Remediation', content: 'Remediation', testId: 'Incident' },
  { value: 'Failure', content: 'Failure', testId: 'Incident' },
];

const IssueTypeSelectFormElement: FC<IssueTypeSelectFormElementProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const issueTypeSelectFormElementTestId = `${testId}-form-element-select-issue-type`;

  return (
    <FormElementWrapper testId={`${issueTypeSelectFormElementTestId}-wrapper`}>
      <Text testId={`${issueTypeSelectFormElementTestId}-title`}>
        {t('issueType')}
      </Text>

      <Controller
        name="issueType"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${issueTypeSelectFormElementTestId}-select`}
            error={!!error}
            multiple={false}
            options={issueTypeOptions}
            placeholder={t('issueTypePlaceholder')}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${issueTypeSelectFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default IssueTypeSelectFormElement;

IssueTypeSelectFormElement.displayName = 'IssueTypeSelectFormElement';