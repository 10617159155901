import React, { FC } from 'react';

import { Sections } from '@soc/alerts/src/components/SidePanel/Panel/Tabs/GeneralSection';
import { NavigationMapKeysType } from '@soc/alerts/src/components/SidePanel/Panel/hooks';
import { BlankText } from '@soc/assets/src/components/CmdbAsset/Panel/components';
import { getDateInPartialDateAndTimeFormat } from '@soc/kit/date';
import { useLanguage, useTranslate } from 'combinezone/utils';

import { Section } from 'components/AlertList/Panel/Tabs/Info/Section';

import { SoftwareTabDataType } from '../Body';

import { SoftwarePanel } from './Components/SoftwarePanel';
import locales from './locales';

export const Panel: FC<{
  testId: string;
  softwareTabData: SoftwareTabDataType;
  pickedRow: number | null;
}> = ({ pickedRow, softwareTabData, testId }) => {
  const t = useTranslate(locales);
  const { language } = useLanguage();
  const data = softwareTabData.data.find((el) => el.id === pickedRow);
  const sectionData = [
    { key: 'name', label: t('name'), value: data?.name },
    {
      key: 'vendor',
      label: t('developer'),
      value: data?.vendor.name || <BlankText />,
    },
    {
      key: 'version',
      label: t('version'),
      value: data?.version || <BlankText />,
    },
    {
      key: 'install_time',
      label: t('installDate'),
      value: data?.install_time ? (
        getDateInPartialDateAndTimeFormat(language, data?.install_time)
      ) : (
        <BlankText />
      ),
    },
  ];
  const tabs = data
    ? [
        {
          key: 'general' as NavigationMapKeysType,
          content: (
            <Sections
              testId={`software-tab-section-${testId}`}
              blockProps={{
                isCollapsedByDefault: false,
                isFullHeight: true,
              }}
              blocks={[
                {
                  id: 'general',
                  title: t('generalInfo'),
                  content: <Section data={sectionData} />,
                },
              ]}
            />
          ),
        },
      ]
    : [];

  return (
    <SoftwarePanel
      testId={`${testId}-software-panel-container`}
      tabs={tabs}
      title={data?.name}
    />
  );
};
