import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError, useApiToastError } from '@soc/kit/api';
import { BaseSelectOption } from 'combinezone/core/Select/types';

import { socProperties } from 'utils/brending';

import { categoryOptionsTTC } from '../../../constants';
import { fetchMainCategories } from '../actions/fetchMainCategories';
import { mainCategoryFetchAbortControllerKey } from '../constants';
import { convertMainCategoriesToOptions } from '../helpers';
import { UseMainCategories } from '../types';

export const useMainCategories: UseMainCategories = () => {
  const apiToastError = useApiToastError();
  const [data, setData] = useState<BaseSelectOption[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inlineSearch, setInlineSearch] = useState('');
  const [selectedIssueType, setSelectedIssueType] = useState('');

  const getMainCategories = useCallback(() => {
    setIsFetching(true);

    fetchMainCategories({ search: inlineSearch, issueType: selectedIssueType })
      .then((res) => {
        const convertedResultsData = convertMainCategoriesToOptions(res);

        setData(convertedResultsData);
        setIsFetching(false);
      })
      .catch((error: Error) => {
        apiToastError(error);
        setData([]);

        if (isAbortError(error)) {
          return;
        }
        setIsFetching(false);
      });
  }, [inlineSearch, selectedIssueType]);

  const getMainCategoriesTTC = useCallback(() => {
    const options = categoryOptionsTTC[selectedIssueType] || [];
    const filteredOptions = options.filter(({value}) => value.includes(inlineSearch));
    setData(filteredOptions);
  }, [inlineSearch, selectedIssueType]);

  useEffect(() => {
    if (isOpen) {
      if (socProperties.brandName !== 'TTC') {
        getMainCategories();
      } else {
        getMainCategoriesTTC();
      }
    }

    return () => {
      abortApiFetch(mainCategoryFetchAbortControllerKey);
      setData([]);
    };
  }, [isOpen, inlineSearch, selectedIssueType]);

  return {
    data,
    isFetching,
    setIsOpen,
    setInlineSearch,
    setSelectedIssueType,
  };
};
