import React, { FC, useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { useDebouncedCallback, useTranslate } from 'combinezone/utils';

import { FormElementWrapper, FormElementError } from '../shared';
import { optionItemWithSpinner } from '../shared/helpers';

import { useMainCategories } from './hooks/useMainCategories';
import locales from './locales';
import { MainCategorySelectFormElementProps } from './types';

const MainCategorySelectFormElement: FC<MainCategorySelectFormElementProps> = ({
  control,
  error,
  selectedIssueType,
  testId,
}) => {
  const t = useTranslate(locales);
  const mainCategorySelectFormElementTestId = `${testId}-form-element-select-main-category`;
  const { data, isFetching, setInlineSearch, setIsOpen, setSelectedIssueType } =
    useMainCategories();

  useEffect(() => {
    if (selectedIssueType) {
      setSelectedIssueType(selectedIssueType.toLowerCase());
    }
  }, [selectedIssueType]);

  const supplementedOptions = useMemo(() => {
    return isFetching ? [optionItemWithSpinner] : data;
  }, [isFetching, data]);

  const mainCategorySearchHandler = useDebouncedCallback(
    (text: string): void => {
      setInlineSearch(text);
    },
    300,
  );

  return (
    <FormElementWrapper
      testId={`${mainCategorySelectFormElementTestId}-wrapper`}
    >
      <Text testId={`${mainCategorySelectFormElementTestId}-title`}>
        {t('mainCategory')}
      </Text>

      <Controller
        name="mainCategory"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${mainCategorySelectFormElementTestId}-select`}
            error={!!error}
            multiple={false}
            options={supplementedOptions}
            placeholder={t('mainCategoryPlaceholder')}
            disabled={!selectedIssueType}
            onSearchStringChange={mainCategorySearchHandler}
            dropdownProps={{
              onOpen: () => setIsOpen(true),
              onClose: () => setIsOpen(false),
            }}
          />
        )}
      />

      {error && (
        <FormElementError
          testId={`${mainCategorySelectFormElementTestId}-error`}
        >
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default MainCategorySelectFormElement;

MainCategorySelectFormElement.displayName = 'MainCategorySelectFormElement';
