import React, { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError } from '@soc/kit/api';
import { Text, useToast, TextLink, Flex } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { fetchCreateIncident } from '../actions/fetchCreateIncident';
import {
  backendNamesToFormNamesMapping,
  incidentCreateFetchAbortControllerKey,
} from '../constants';
import locales from '../locales';
import { SetNewIncidentProps, UseCreateIncident } from '../types';

export const useCreateIncident: UseCreateIncident = ({
  onClose,
  setError,
  setIncidentKey,
}) => {
  const { toastError, toastSuccess } = useToast();
  const t = useTranslate(locales);
  const [isFetching, setIsFetching] = useState(false);
  const [newIncident, setNewIncident] = useState<SetNewIncidentProps>({});
  const [isClose, setIsClose] = useState(true);

  const createIncident = useCallback(() => {
    setIsFetching(true);

    fetchCreateIncident(newIncident)
      .then(({ key }) => {
        setIsFetching(false);

        toastSuccess({
          title: () => (
            <Flex gap="5px">
              <Text variant="placeholder" size="lg">
                {t('createSuccessPartOne')}
              </Text>
              <TextLink
                testId="link-to-incident"
                to={`inc/${key}`}
                target="_blank"
              >
                {key}
              </TextLink>
              <Text variant="placeholder" size="lg">
                {t('createSuccessPartTwo')}
              </Text>
            </Flex>
          ),
        });

        if (isClose) {
          onClose();
        } else {
          setIncidentKey(key);
        }
      })
      .catch((error: Error) => {
        if (error.data) {
          for (const [key, value] of Object.entries(error.data)) {
            setError(backendNamesToFormNamesMapping[key], {
              message: value.toString(),
            });
          }
        } else {
          toastError({ title: t('createError') });
        }
        if (isAbortError(error)) {
          return;
        }
        setIsFetching(false);
      });
  }, [newIncident, onClose, setIncidentKey, isClose]);

  useEffect(() => {
    if (newIncident.tenant) {
      createIncident();
    }

    return () => {
      abortApiFetch(incidentCreateFetchAbortControllerKey);
    };
  }, [newIncident]);

  return {
    isFetching,
    setNewIncident,
    setIsClose,
  };
};