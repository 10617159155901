import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { useHistory, useLocation } from 'react-router-dom';

import { Layout } from '@common/soc-react-kit';
import { Heading, OverlaySpinner } from 'combinezone/core';

import CreateIncModal from 'components/CreateIncModal';

import DownloadIncCSVControlContainer from '../../containers/DownloadIncCSVControlContainer';
import FilterResetContainer from '../../containers/FilterControlContainer';
import FilterPanelContainer from '../../containers/FilterPanelContainer';
import IncidentCard from '../../containers/IncidentList-CardContainer';
import Panel from '../../containers/IncidentList-PanelContainer';
import SortControl from '../../containers/IncidentList-SortControlContainer';
import { ID_FIELD, INCIDENTS_ENTITY } from '../../services/api';
import incidentMessagesService from '../../services/incidentMessagesService';
import { setFilterFromQuerysringGeneral } from '../../utils/helpers';

import SearchBlock from './SearchBlock';

import './IncidentList.scss';

const { Container, Content } = Layout;

export default function IncidentList({
  fetchMitreMapping,
  fetchMore,
  filters,
  firstFetch,
  hasMore,
  incidents,
  isFetching,
  localSaveFilters,
  locale,
  onChangeSearch,
  ordering,
  search = '',
  setInitFilter,
  setInitSearch,
  setInitSort,
  updateFunction,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { pathname, search: querystring } = useLocation();

  useEffect(() => {
    incidentMessagesService.start();
    return () => incidentMessagesService.stop();
  });

  useEffect(() => {
    setFilterFromQuerysringGeneral({
      querystring,
      setFilter: setInitFilter,
      setSort: setInitSort,
      setSearch: setInitSearch,
    });
  }, []);

  useEffect(() => {
    localSaveFilters({ entity: INCIDENTS_ENTITY, history, pathname });
  }, [localSaveFilters, history, pathname, filters, search, ordering]);

  useEffect(() => {
    firstFetch();
  }, [firstFetch]);

  const loadMore = useCallback(() => {
    if (hasMore) {
      setLoading(true);
      fetchMore().then(() => setLoading(false));
    }
  }, [fetchMore, hasMore]);

  useEffect(() => {
    fetchMitreMapping();
  }, [fetchMitreMapping]);

  return (
    <Container panel className="IncidentList">
      <Content className="IncidentListContainer">
        <div className="IncidentListHeader">
          <div className="IncidentListHeader-ControlsRow">
            <div className="IncidentListHeader-Search">
              <SearchBlock
                locale={locale}
                defaultText={search}
                onSearch={onChangeSearch}
              />
            </div>
            <FilterResetContainer updateFunction={updateFunction} />
            <SortControl />
            <CreateIncModal/>
            <DownloadIncCSVControlContainer />
          </div>
          <div className="IncidentListHeader-FiltersRow">
            <FilterPanelContainer
              updateFunction={updateFunction}
              dispatch={dispatch}
            />
          </div>
        </div>
        <div
          className={`IncidentListBody${
            isFetching ? ' IncidentListBody_fetching' : ''
          }`}
        >
          {isFetching && <OverlaySpinner />}
          <div className="IncidentListBody-Cards">
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={loadMore}
              hasMore={isLoading ? false : hasMore}
              useWindow={false}
            >
              {incidents.map((incident) => (
                <IncidentCard incident={incident} key={incident[ID_FIELD]} />
              ))}
            </InfiniteScroll>
            <button
              onClick={() =>
                updateFunction({
                  resultYourChoise,
                })
              }
            />
            {incidents.length === 0 && !hasMore && (
              <div className="IncidentListBody-NoData">
                <Heading size="md">
                  <Translate value="incidentList.noData" />
                </Heading>
              </div>
            )}
          </div>
        </div>
      </Content>
      <Panel />
    </Container>
  );
}
