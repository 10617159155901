import { BaseSelectOption } from 'combinezone/core';

import { MainCategoriesItem } from './types';

export const convertMainCategoriesToOptions = (
  data: MainCategoriesItem[],
): BaseSelectOption[] => {
  if (!data.length) {
    return [];
  }

  return data.map(({ id, title }) => ({
    value: id,
    content: title,
    testId: `${id}`,
  }));
};
