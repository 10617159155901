import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Select, Text } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { FormElementWrapper, FormElementError } from '../shared';

import { usePriorityOptions } from './hooks/usePriorityOptions';
import locales from './locales';
import { TenantSelectFormElementProps } from './types';

const PrioritySelectFormElement: FC<TenantSelectFormElementProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const { priorityOptions } = usePriorityOptions();
  const prioritySelectFormElementTestId = `${testId}-form-element-select-priority`;

  return (
    <FormElementWrapper testId={`${prioritySelectFormElementTestId}-wrapper`}>
      <Text testId={`${prioritySelectFormElementTestId}-title`}>
        {t('priority')}
      </Text>

      <Controller
        name="priority"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Select
            {...field}
            testId={`${prioritySelectFormElementTestId}-select`}
            error={!!error}
            multiple={false}
            options={priorityOptions}
            placeholder={t('priorityPlaceholder')}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${prioritySelectFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default PrioritySelectFormElement;

PrioritySelectFormElement.displayName = 'PrioritySelectFormElement';