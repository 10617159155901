import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Redirect, useParams } from 'react-router-dom';

import { Block } from '@bizone/ui-bundle/esm/Block';
import { Button } from '@bizone/ui-bundle/esm/Button';
import { Header } from '@bizone/ui-bundle/esm/Header';

import { Layout, ListSection } from '@common/soc-react-kit';

import Versions from '../../containers/ArticlePage-VersionsContainer';
import {
  ARTICLES_ENDPOINT,
  INSTRUCTIONS_DESCRIPTION,
  INSTRUCTIONS_NAME,
} from '../../services/api';
import { instructionFormatter } from '../../utils/helpers';
import Anchors from '../Anchors';
import Back from '../Back';
import DocumentAnotherLanguages from '../DocumentAnotherLanguages';
import Description from '../InstructionPage/Description';
import { TitleComponent } from '../TitleComponent';

import './ArticlePage.scss';

const { getAttachments, mainInfoFormatter } = instructionFormatter;

export function ArticlePage({
  article,
  closeInstructionCard,
  downloadFile,
  fetchArticleCard,
  fetchError,
  locale,
}) {
  const { id } = useParams();
  const [hasSecondDocument, setHasSecondDocument] = useState(true);

  const name = article[INSTRUCTIONS_NAME] || I18n.t('articlesCard.noTitle');

  const anchors = [
    {
      title: <Translate value="articlesCard.mainBlockTitle" />,
      id: 'art-main-info',
    },
    {
      title: <Translate value="articlesCard.instruction" />,
      id: 'art-article',
    },
  ];

  const listData = useMemo(
    () => ({
      mainInfo: {
        ...mainInfoFormatter(article, locale).mainInfo,
        [I18n.t('articlesCard.attachments')]: getAttachments(article),
        [I18n.t('documentationCardBase.inAnotherLanguage')]:
          hasSecondDocument ? (
            <DocumentAnotherLanguages
              type="article"
              currentId={Number(id)}
              document={article.document}
              setHasSecondDocument={setHasSecondDocument}
            />
          ) : null,
      },
      locale,
    }),
    [article, hasSecondDocument, id, locale],
  );

  const fetchArticle = useCallback(() => {
    fetchArticleCard(id);
  }, [fetchArticleCard, id]);

  const downloadPDF = useCallback(() => {
    downloadFile(`${ARTICLES_ENDPOINT}${id}/download_pdf/`, `${name}.pdf`);
  }, [downloadFile, id, name]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  useEffect(() => {
    return closeInstructionCard;
  }, [closeInstructionCard]);

  if (fetchError) {
    return <Redirect to="/docs/articles" />;
  }

  return (
    <React.Fragment>
      <TitleComponent title={name} />
      <Layout.BreadCrumb>
        <Back to="/docs/articles"> {name}</Back>
        <Button basic leftIcon="file-download" onClick={downloadPDF}>
          <Translate value="documentationCardBase.downloadPDF" />
        </Button>
      </Layout.BreadCrumb>
      <Layout.Container
        dual
        id="InstructionCardContainer"
        className="article-card"
      >
        <Layout.Content>
          <Anchors
            key={anchors.length}
            anchors={anchors}
            containerId="InstructionCardContainer"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexBasis: '100%',
              marginLeft: 250,
            }}
          >
            <section id="art-main-info">
              <Block
                collapsible
                title={
                  <Header size={16}>
                    <Translate value="articlesCard.mainBlockTitle" />
                  </Header>
                }
              >
                <Block.Content cls="mainInfo">
                  <div>
                    <ListSection
                      header={<Translate value="articlesCard.mainInfo" />}
                      data={listData.mainInfo}
                    />
                  </div>
                </Block.Content>
              </Block>
            </section>
            <section id="art-article">
              <Block
                collapsible
                title={
                  <Header size={16}>
                    <Translate value="articlesCard.instruction" />
                  </Header>
                }
                headerControls={
                  <div>
                    <Versions id={id} />
                  </div>
                }
              >
                <Block.Content>
                  {article[INSTRUCTIONS_DESCRIPTION] ? (
                    <Description
                      more={I18n.t('articlesCard.more')}
                      less={I18n.t('articlesCard.less')}
                      value={article[INSTRUCTIONS_DESCRIPTION]}
                    />
                  ) : (
                    I18n.t('articlesCard.noDescription')
                  )}
                </Block.Content>
              </Block>
            </section>
          </div>
        </Layout.Content>
      </Layout.Container>
    </React.Fragment>
  );
}

ArticlePage.defaultProps = {
  article: {},
  fetchError: false,
};
