import { api } from '@soc/kit/api';
import { SOC_URL } from '@soc/kit/api/constants';

import { incidentCreateFetchAbortControllerKey } from '../constants';
import { FetchCreateIncident, FetchCreateIncidentRes } from '../types';

export const fetchCreateIncident: FetchCreateIncident = ({
  additionalCategories,
  description,
  issueType,
  mainCategory,
  priority,
  subject,
  tenant,
}) => {
  const body = JSON.stringify({
    system: tenant,
    summary: subject,
    issuetype: issueType,
    priority,
    description,
    primary_category: mainCategory,
    secondary_category: additionalCategories,
  });
  return api<FetchCreateIncidentRes>(`${SOC_URL}incs/`, {
    method: 'post',
    body,
    abortControllerKey: incidentCreateFetchAbortControllerKey,
  });
};