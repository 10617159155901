import React, { FC, useState } from 'react';

import { MultiFileInput } from 'combinezone/core';
import { MultiFileInputValue } from 'combinezone/core/MultiFileInput/types';
import { useTranslate } from 'combinezone/utils';

import { FormElementWrapper } from '../shared';

import locales from './locales';
import { ImageLoaderProps } from './types';

const ImagesLoader: FC<ImageLoaderProps> = ({ setFileValues, testId }) => {
  const t = useTranslate(locales);
  const testIdFileLoader = `${testId}-file-loader`;

  const [value, setValue] = useState<MultiFileInputValue>();

  const onChangeImages = (images: MultiFileInputValue): void => {
    setValue(images);
    setFileValues(images.acceptedFiles);
  };

  return (
    <FormElementWrapper
      fillWidth
      id="MultiFileInput"
      testId={`${testIdFileLoader}-attach`}
    >
      <MultiFileInput
        showFileList
        maxFiles={10}
        maxSizeMB={50}
        onChange={onChangeImages}
        testId={testIdFileLoader}
        placeholderLabel={t('uploadImages')}
        placeholder={t('imageLoaderPlaceholder')}
        value={value}
      />
    </FormElementWrapper>
  );
};

export default ImagesLoader;

ImagesLoader.displayName = 'ImagesLoader';
