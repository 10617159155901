import { useCallback, useEffect, useState } from 'react';

import { abortApiFetch, isAbortError, useApiToastError } from '@soc/kit/api';
import { BaseSelectOption } from 'combinezone/core/Select/types';

import { socProperties } from 'utils/brending';

import { categoryOptionsTTC } from '../../../constants';
import { fetchAdditionalCategories } from '../actions/fetchAdditionalCategories';
import { additionalCategoriesFetchAbortControllerKey } from '../constants';
import { convertAdditionalCategoriesToOptions } from '../helpers';
import { UseAdditionalCategories } from '../types';

export const useAdditionalCategories: UseAdditionalCategories = () => {
  const apiToastError = useApiToastError();
  const [data, setData] = useState<BaseSelectOption[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inlineSearch, setInlineSearch] = useState('');
  const [selectedIssueType, setSelectedIssueType] = useState('');

  const getAdditionalCategories = useCallback(() => {
    setIsFetching(true);

    fetchAdditionalCategories({
      search: inlineSearch,
      issueType: selectedIssueType,
    })
      .then((res) => {
        const convertedResultsData = convertAdditionalCategoriesToOptions(res);

        setData(convertedResultsData);
        setIsFetching(false);
      })
      .catch((error: Error) => {
        apiToastError(error);
        setData([]);

        if (isAbortError(error)) {
          return;
        }
        setIsFetching(false);
      });
  }, [inlineSearch, selectedIssueType]);

  const getAdditionalCategoriesTTC = useCallback(() => {
    const options = categoryOptionsTTC[selectedIssueType] || [];
    const filteredOptions = options.filter(({value}) => value.includes(inlineSearch));
    setData(filteredOptions);
  }, [inlineSearch, selectedIssueType]);

  useEffect(() => {
    if (isOpen) {
      if (socProperties.brandName !== 'TTC') {
        getAdditionalCategories();
      } else {
        getAdditionalCategoriesTTC();
      }
    }

    return () => {
      abortApiFetch(additionalCategoriesFetchAbortControllerKey);
      setData([]);
    };
  }, [isOpen, inlineSearch, selectedIssueType]);

  return {
    data,
    isFetching,
    setIsOpen,
    setInlineSearch,
    setSelectedIssueType,
  };
};
