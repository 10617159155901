import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { Input, Text } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';


import { FormElementWrapper, FormElementError } from '../shared';

import { maxLength } from './constants';
import locales from './locales';
import { SubjectInputFormElementProps } from './types';

const SubjectInputFormElement: FC<SubjectInputFormElementProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const descriptionInputFormElementTestId = `${testId}-form-element-input-subject`;

  return (
    <FormElementWrapper testId={`${descriptionInputFormElementTestId}-wrapper`}>
      <Text testId={`${descriptionInputFormElementTestId}-title`}>
        {t('subject')}
      </Text>

      <Controller
        name="subject"
        control={control}
        rules={{
          required: t('requiredField'),
          maxLength: {
            value: maxLength,
            message: `${t('maxLength')} - ${maxLength}`,
          },
        }}
        render={({ field }) => (
          <Input
            {...field}
            testId={`${descriptionInputFormElementTestId}-subject-input`}
            placeholder={t('subjectPlaceholder')}
            isInvalid={!!error}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${descriptionInputFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default SubjectInputFormElement;

SubjectInputFormElement.displayName = 'SubjectInputFormElement';