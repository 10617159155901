import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Text, Wysiwyg } from 'combinezone/core';
import { useTranslate } from 'combinezone/utils';

import { FormElementWrapper, FormElementError } from '../shared';

import { configTemplate } from './constants';
import locales from './locales';
import { DescriptionWisiwygFormElementProps } from './types';

const DescriptionWisiwygFormElement: FC<DescriptionWisiwygFormElementProps> = ({
  control,
  error,
  testId,
}) => {
  const t = useTranslate(locales);
  const descriptionWisiwigFormElementTestId = `${testId}-form-element-wisiwig-description`;

  const config = useMemo(() => {
    return {
      ...configTemplate,
      placeholder: t('descriptionPlaceholder'),
    };
  }, [t]);

  return (
    <FormElementWrapper
      testId={`${descriptionWisiwigFormElementTestId}-wrapper`}
    >
      <Text testId={`${descriptionWisiwigFormElementTestId}-title`}>
        {t('description')}
      </Text>

      <Controller
        name="description"
        control={control}
        rules={{
          required: t('requiredField'),
        }}
        render={({ field }) => (
          <Wysiwyg
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            config={config}
            testId={`${descriptionWisiwigFormElementTestId}-description-wysiwyg`}
          />
        )}
      />

      {error && (
        <FormElementError testId={`${descriptionWisiwigFormElementTestId}-error`}>
          {error.message}
        </FormElementError>
      )}
    </FormElementWrapper>
  );
};
export default DescriptionWisiwygFormElement;

DescriptionWisiwygFormElement.displayName = 'DescriptionWisiwygFormElement';
